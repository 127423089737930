import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InvoiceHttpService } from '@epione/modules/invoice/services/http/invoice-http.service';
import { LoadingStateService } from '@epione/shared/services/global/loading-state.service';
import { AccountModel } from '@epione/shared/models/account.model';
import { catchError, debounceTime, distinctUntilChanged, finalize, map, mapTo, switchMap, take, tap } from 'rxjs/operators';
import { InvoiceModel } from '@epione/shared/models/invoice.model';
import { Observable, Subscription, forkJoin, of, throwError } from 'rxjs';
import { PracticeMemberModel } from '@epione/shared/models/main/practice-member.model';
import { SuccessDialogService } from '@epione/shared/dialogs/success-dialog.service';
import { ErrorDialogService } from '@epione/shared/dialogs/error-dialog.service';
import { CaseLifecycleModel } from '@epione/shared/models/case-lifecycle.model';
import { ActiveUserService } from '@epione/shared/services/global/active-user.service';
import { RoleAlias } from '@epione/shared/types/role.enum';
import { LineItemTypeHttpService } from '../services/http/line-item-type-http.service';
import * as moment from 'moment';
import { CurrencyModel } from '@epione/shared/models/currency.model';
import { InvoiceStatusId } from '@epione/shared/types/invoice-status';
import { NappiCodeModel } from '@epione/shared/models/codes/nappi.model';
import { ICD10CodeModel } from '@epione/shared/models/codes/icd10.model';
import { InvoiceActionService } from '../services/invoice-action.service';
import { AccountsHttpService } from '@epione/modules/account/services/accounts-http.service';
import { ClaimTransactionHttpService } from '../services/http/claim-transaction-http.service';
import { BillablePresetService, LineItemObject } from '../services/billable-preset.service';
import { LineItemTypeModel } from '@epione/shared/models/line-item-type.model';
import { LineItemTypeId } from '@epione/shared/types/line-item-types';
import { BillablePreset } from '../services/presets';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SelectBasketComponent } from '@epione/modules/settings/basket/select-basket/select-basket.component';
import { BasketModel } from '@epione/shared/models/basket.model';
import { PatientFlagModel } from '@epione/shared/models/patient-flag.model';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { InvoicePaymentHttpService } from '../services/http/invoice-payment-http.service';
import { PaymentModel } from '@epione/shared/models/payment.model';
import { ClaimTransactionModel } from '@epione/shared/models/medikredit/claim-transaction.model';
import { ClaimTransactionItemModel } from '@epione/shared/models/medikredit/claim-transaction-line-item.model';
import { ClaimResponseModel, MedikreditClaimHttpService } from '../services/http/medikredit-claim-http.service';
import { BillingClaimStatusAlias } from '@epione/shared/types/billing-claim-status';
import { ClaimLineCodeGroup, ClaimLineCodeGrouping } from '@epione/shared/config/claim-codes.config';
import { currencyToDecimal } from '@epione/shared/util';

export interface LineItemUrlParam {
	line_item_type_id?: number;
	tariff_code_txt?: string;
	tariff_code_id?: number;
	icd10_codes?: number[];
	nappi_code_id?: number;
	description?: string;
	quantity?: number;
	unit_amount?: number;
}

export interface InvoiceUrlParams {
	practitionerId?: number;
	practitionerRoleId?: number;
	patientId?: number;
	accountId?: number;
	caseId?: number;
	lifecycleId?: number;
	lineItems?: LineItemUrlParam[];
	presets?: BillablePreset[];
	dueDate?: string;
	invoiceDate?: string;
}

@Component({
	selector: 'epione-invoice-create',
	templateUrl: './invoice-save.component.html',
	styleUrls: ['./invoice-save.component.scss']
})
export class InvoiceSaveComponent implements OnInit, AfterViewInit, OnDestroy {
	public action: 'create' | 'update' = 'create';
	public invoiceLoaded: boolean = false;
	public lineItemTypes: LineItemTypeModel[] = [];
	public currency: CurrencyModel;
	public totalDiscount: number = 0;

	public invoiceForm!: FormGroup;
	public loadingStatus: string | null = 'Loading Invoice Data';

	public componentsLoaded: { [key: string]: boolean } = {
		practitioner: false,
		account: false,
		case: false
	};

	public invoiceData?: InvoiceModel;
	public accountId?: number;
	public practitionerId?: number;
	public practitionerRoleId?: number;
	public caseId?: number;
	public lifecycleId?: number;
	public canEditDoctor: boolean = true;
	public expandedRows: number[] = [];
	public dpcPatientFlag: PatientFlagModel | null = null;
	public tab: 'Invoice' | 'Payments' = 'Invoice';

	@ViewChild('claimModal') claimModal!: TemplateRef<any>;
	@ViewChild('descriptionModal') descriptionModal!: TemplateRef<any>;
	@ViewChildren('lineHandle', { read: ElementRef }) lineHandles!: QueryList<ElementRef>;
	@ViewChild('lineSpacer', { read: ElementRef }) lineSpacer!: ElementRef<any>;
	public claimModalRef?: NgbModalRef;
	public claimResult?: ClaimResponseModel;
	public activeClaimTransaction?: ClaimTransactionModel;
	public activeDescriptionControl?: FormGroup;
	public descriptionModalRef?: NgbModalRef;
	public payments: PaymentModel[] = [];

	private viewSub?: Subscription;
	private firstLine: {
		observer?: ResizeObserver,
		elem?: ElementRef
	} = {};
	private _account: AccountModel | null = null;
	private _practitioner: PracticeMemberModel | null = null;
	private _lifecycle: CaseLifecycleModel | null = null;

	constructor(
		private fb: FormBuilder,
		private route: ActivatedRoute,
		private activeUserService: ActiveUserService,
		private invoiceHttpService: InvoiceHttpService,
		private loadingStateService: LoadingStateService,
		private successDialogService: SuccessDialogService,
		private errorDialogService: ErrorDialogService,
		private lineItemTypeHttpService: LineItemTypeHttpService,
		private router: Router,
		private invoiceActionService: InvoiceActionService,
		private accountHttpService: AccountsHttpService,
		private billablePresetService: BillablePresetService,
		private modalService: NgbModal,
		private invoicePaymentHttpService: InvoicePaymentHttpService,
		private claimTransactionHttpService: ClaimTransactionHttpService,
		private medikredditClaimHttpService: MedikreditClaimHttpService,
		private zone: NgZone
	) {
		this.currency = this.activeUserService.currency as CurrencyModel;
		// if its a practitioner
		if (this.activeUserService.user?.role.alias !== RoleAlias.PM) {
			this.practitionerId = this.activeUserService.id as number;
			this.practitionerRoleId = this.activeUserService.user?.role.id as number;
			this.canEditDoctor = false;
		}
		this.lineItemTypeHttpService.listAll().pipe(
			take(1),
			tap(res => this.lineItemTypes = res.filter(({ is_meta }) => !is_meta)),
		).subscribe();

		this.initInvoiceForm();
	}

	ngOnInit(): void {
		this.route.params.subscribe({
			next: res => {
				if ('id' in res && !!res.id) {
					this.action = 'update';
					this.init(res.id);
				} else {
					const params = this.route.snapshot.queryParams;
					if ('options' in params && !!params.options) {
						// options can be base64 serialized options object
						this.loadingStatus = 'Fetching Patient Account Records';
						this.initFromParams(params.options).then(() => {
							this.invoiceLoaded = true; // nothing to load
							this.loadingStatus = null;
						});
					} else {
						this.invoiceLoaded = true; // nothing to load
						this.loadingStatus = null;
					}
				}
			}
		});
	}

	ngAfterViewInit() {
		this.viewSub = this.lineHandles.changes.pipe(
			tap(([first]) => {
				if (!this.firstLine.observer) {
					this.firstLine.observer = new ResizeObserver(([e]) => {
						this.zone.run(() => {
							console.log(e);
							this.lineSpacer.nativeElement.style.width = e.contentRect.width + 'px';
						});
					});
				}
				if (this.firstLine.elem !== first) {
					this.firstLine.observer.disconnect();
					this.firstLine.elem = first;

					if (this.firstLine.elem) {
						this.firstLine.observer.observe(this.firstLine.elem.nativeElement);
					} else {
						this.zone.run(() => {
							delete this.lineSpacer.nativeElement.style.width;
						});
					}
				}
			})
		).subscribe();
	}

	ngOnDestroy(): void {
		if (this.firstLine.observer) this.firstLine.observer.disconnect();
		if (this.viewSub) this.viewSub.unsubscribe();
	}

	get total(): number {
		return (this.invoiceForm.get('line_items') as FormArray).controls.reduce<number>(
			(total: number, control: AbstractControl) => {
				let tax = 0.0;
				if (this.invoiceData && this.invoiceData.tax_status_id === 1 && this.invoiceData.lineItems) {
					const lineItem = this.invoiceData.lineItems.find(v => v.id === control.get('id')?.value);
					if (lineItem) {
						tax = lineItem.tax_amount ? lineItem.tax_amount : 0.0;
					}
				}
				return total
					+ this.getLineAmount(control)
					+ tax;
			},
			0.0
		);
	}

	get account(): AccountModel | null {
		return this._account;
	}
	set account(val: AccountModel | null) {
		this._account = val;
		this.dpcPatientFlag = null;
		this.invoiceForm.get('account_id')?.patchValue(val ? val.id : val);
		const flag = this.account?.patient?.patientFlags?.find(flag => flag.alias === 'dpc_pilot');
		if (flag && flag.owner_id === this.activeUserService.practiceId) {
			this.dpcPatientFlag = flag;
		}
	}

	get practitioner(): PracticeMemberModel | null {
		return this._practitioner;
	}
	set practitioner(val: PracticeMemberModel | null) {
		this._practitioner = val;
		this.invoiceForm.get('practitioner_id')?.patchValue(val ? val.id : val);
		this.invoiceForm.get('practitioner_role_id')?.patchValue(val ? val.role_id : val);
	}

	get lifecycle(): CaseLifecycleModel | null {
		return this._lifecycle;
	}
	set lifecycle(val: CaseLifecycleModel | null) {
		this._lifecycle = val;
		this.invoiceForm.get('case_lifecycle_id')?.patchValue(val ? val.id : val);
		// const date = moment(val?.updated_at);
		// this.invoiceForm.get('invoice_date')?.patchValue(val ? date.toDate() : val);
	}

	get lineItemsFormArray(): FormArray {
		return this.invoiceForm.get('line_items') as FormArray;
	}

	get useMedikredit(): boolean {
		return this.activeUserService.practice?.use_medikredit === true;
	}

	get activeDescription(): FormControl {
		return this.activeDescriptionControl?.get('description') as FormControl;
	}

	get patientLiable(): number {
		return this.activeClaimTransaction ? this.total - this.activeClaimTransaction?.approved_nett : this.total;
	}

	get totalTax(): number | null {
		return this.invoiceData && this.invoiceData.tax_status_id === 1 && this.invoiceData.lineItems ? this.invoiceData.lineItems.reduce((o, v) => o + v.tax_amount, 0.0) : null;
	}

	get claimIsSubmitted(): boolean {
		return !!this.activeClaimTransaction && [
			BillingClaimStatusAlias.APPROVED,
			BillingClaimStatusAlias.PROCESSED
		].includes(this.activeClaimTransaction.status.alias as BillingClaimStatusAlias);
	}

	hasClaimItems(control: AbstractControl, minSevevrity?: number) {
		if (control.value.id) {
			if (minSevevrity) {
				return this.claimItems(control.value.id).filter(
					item => (item.codes || []).reduce<boolean>(
						(o, v) => o || v.severity >= minSevevrity,
						false
					)
				).length;
			}
			return this.claimItems(control.value.id).length;
		}
		return false;
	}

	claimItems(id: number | null) {
		return this.activeClaimTransaction?.items?.filter(item => id ? id === item.lineItem?.id : !item.lineItem) || [];
	}

	claimMessagesBySection(id: null | number, section: ClaimLineCodeGroup): Array<{ severity: number, code?: string, message: string }> {
		return (this.activeClaimTransaction?.items || []).reduce<Array<{ severity: number, code?: string, message: string }>>(
			(out, item) => {
				if (
					(id === null && item.line_item_id === null)
					|| id === item.lineItem?.id
				) {
					if (section === 'tariff') {
						if (item.oti_description) {
							out.push({
								message: `Option Type: ${item.oti_description} ${item.oti_option}`,
								severity: 0
							});
						}
					}
					if (section === 'price') {
						if (this.payments.length) {
							out.push({
								message: `Unable To Edit As Payments Have Been Made`,
								severity: 0
							});
						}
						if (item.approved_nett) {
							out.push({
								message: `Approved Claim Amount: ${currencyToDecimal(item.approved_nett)}`,
								severity: 0
							});
						}
						if (item.overcharge) {
							out.push({
								message: `Overcharge: ${currencyToDecimal(item.overcharge)}`,
								severity: 1
							});
						}
						if (item.claim_gross && item.bhf_nhrpl_gross && item.claim_gross > item.bhf_nhrpl_gross) {
							out.push({
								message: `BHF / NHRPL Price: ${currencyToDecimal(item.bhf_nhrpl_gross)}`,
								severity: 0
							});
						}
						if (item.approved_levy) {
							out.push({
								message: `Patient Liable: ${currencyToDecimal(item.approved_levy)}`,
								severity: 1
							});
						}

						if (item.surcharges && item.surcharges.length > 0) {
							item.surcharges.forEach(surcharge => {
								out.push({
									message: `Surcharge: ${surcharge.code} ${currencyToDecimal(surcharge.amount)} - ${surcharge.description}`,
									severity: 1
								});
							})
						}
					}
					(item.codes || []).forEach(code => {
						if (ClaimLineCodeGrouping[code.code]) {
							if (ClaimLineCodeGrouping[code.code] === section) {
								out.push({
									code: code.code,
									message: code.description,
									severity: code.severity
								});
							}
						} else if (['tariff', 'totals'].includes(section)) {
							out.push({
								code: code.code,
								message: code.description,
								severity: code.severity
							});
						}
					});
				}
				return out;
			},
			[
				...(section === 'totals' ? [{
					message: `Claim Status: ${this.activeClaimTransaction!.status.name}`,
					severity: (
						[
							BillingClaimStatusAlias.APPROVED,
							BillingClaimStatusAlias.PROCESSED
						].includes(this.activeClaimTransaction!.status.alias as BillingClaimStatusAlias)
							? 0 : (
								BillingClaimStatusAlias.REJECTED === this.activeClaimTransaction!.status.alias
									? 2 : 1
							)
					)
				}] : [])
			]
		);
	}

	setTab(tab: 'Invoice' | 'Payments') {
		this.tab = tab;
	}

	toggleExpandedRow(idx: number) {
		const foundIdx = this.expandedRows.findIndex(v => v === idx);
		if (foundIdx === -1) {
			this.expandedRows.push(idx);
		} else {
			this.expandedRows.splice(foundIdx, 1);
		}
	}

	public getLineAmount(control: AbstractControl): number {
		const value = (control as FormGroup).getRawValue();
		let lineAmount = value.unit_amount * value.quantity;
		if (value.discount_rate) {
			lineAmount = lineAmount * (1 - value.discount_rate / 100);
		}
		return lineAmount;
	}

	public getSummaryText(control: AbstractControl): string {
		const value = (control as FormGroup).getRawValue();
		return [
			value.tariff_code_txt ? value.tariff_code_txt : (value.tariff_code_json ? value.tariff_code_json.Code : null),
			value.nappi_code_id ? this.resolveNAPPIDescription(value.nappi_code_id) : null,
			...(value.icd10_codes && Array.isArray(value.icd10_codes) ? value.icd10_codes.map((v: any) => this.resolveICD10Description(v)) : [null])
		].filter(Boolean).join(' | ');
	}

	public applyTotalDiscount(discount_rate: number) {
		(this.invoiceForm.get('line_items') as FormArray).controls.forEach(control => {
			const discountControl = control.get('discount_rate') as FormControl;
			discountControl.patchValue(discount_rate);
			discountControl[discount_rate ? 'disable' : 'enable']();
		})
	}

	public addBasket() {
		let closure = (basket: BasketModel) => {
			basket.basketItems.sort((a, b) => a.order > b.order ? 1 : (b.order > a.order ? -1 : 0)).forEach(item => {
				const lineItems = (this.invoiceForm.get('line_items') as FormArray).controls;
				const match = lineItems.find((c: AbstractControl) => {
					const i = c.value as LineItemObject;
					return i.tariff_code_txt === item.tariff_code_txt
						&& this.enforceID(i.nappi_code_id) === item.nappi_code_id
						&& i.unit_amount === item.unit_amount
						&& i.icd10_codes?.map(c => c.id).sort().join(':') === item.icd10Codes?.map(c => c.id).sort().join(':')
				});
				if (match) {
					match.patchValue({ quantity: match.value.quantity + item.quantity });
				} else {
					this.addLineItem({
						tariff_code_txt: item.tariff_code_txt,
						// tariff_code_id: item.tariffCode, // @TODO: tariffs
						icd10_codes: item.icd10Codes,
						nappi_code_id: item.nappiCode,
						quantity: item.quantity,
						unit_amount: item.unit_amount,
					}, true);
				}
			});
			modalRef.close();
		}
		const modalRef = this.modalService.open(SelectBasketComponent, { size: 'xl', centered: true });
		modalRef.componentInstance.closure = closure;
	}

	public drop(event: CdkDragDrop<string[]>) {
		const currentGroup = this.lineItemsFormArray.at(event.previousIndex);
		this.lineItemsFormArray.removeAt(event.previousIndex);
		this.lineItemsFormArray.insert(event.currentIndex, currentGroup);
	}

	public addLineItem(data: LineItemObject = {}, isDataFromBasket: Boolean = false): FormGroup {
		const service_date = data.service_date
			? moment(data.service_date).toDate()
			: this.invoiceForm.get('invoice_date')?.value
				? moment(this.invoiceForm.get('invoice_date')?.value).toDate()
				: moment().toDate();

		const control = this.fb.group({
			id: [data['id'] || null],
			service_date: [service_date || null, Validators.required],
			line_item_type_id: [data['line_item_type_id'] || null],
			tariff_code_txt: [data['tariff_code_txt'] || null],
			tariff_code_json: [data['tariff_code_json'] || null],
			// tariff_code_id: [data['tariff_code_id'] || null], // @TODO: when we have tariffs
			icd10_codes: [data['icd10_codes'] || null],
			nappi_code_id: [data['nappi_code_id'] || null],
			description: [data['description'] || null, Validators.required],
			quantity: [data['quantity'] || (data.line_item_type_id === LineItemTypeId.META_MEDICAL_AID ? null : 1), Validators.required],
			unit_amount: [data['unit_amount'] || null, Validators.required],
			discount_rate: [data['discount_rate'] || this.totalDiscount || null, [Validators.min(0), Validators.max(100)]]
		});

		// try to write description if empty
		if (data.description === null || data.description === undefined || data.description === '') {
			control.get('description')?.setValue(this.resolveDescription(control.value), { emitEvent: false });
		}

		if (this.totalDiscount) {
			control.get('discount_rate')?.disable();
		}

		if (control.value.line_item_type_id === LineItemTypeId.META_MEDICAL_AID) {
			// meta fields are disabled
			control.disable();
		} else {
			if (isDataFromBasket) {
				const description = this.resolveDescription(control.value);
				control.get('description')?.setValue(description, { emitEvent: false });
			}
			// other fields have listeners attached to generate description
			control.valueChanges.pipe(
				debounceTime(300), // debounce
				distinctUntilChanged((prev, curr) => {
					// only track changes to these specific fields
					return [
						'line_item_type_id',
						'tariff_code_txt',
						'tariff_code_json',
						'icd10_codes',
						'nappi_code_id',
					].reduce<boolean>((out, key) => out && curr[key] === prev[key], true);
				})
			).subscribe({
				next: (changes) => {
					// we are now mutating the description any time it is changed, as we are pattern matching and replacing the data we care about.
					// This may result in overwriting custom changes made by users, and this was state and agreed upon with the scoping & management team
					control.get('description')?.setValue(this.resolveDescription(changes), { emitEvent: false });
					// update discount for dpc pilot patients
					if (changes.tariff_code_txt && this.dpcPatientFlag && !this.dpcPatientFlag.data.in_arrears) {
						this.setDpcDiscountRate(control, changes.tariff_code_txt);
					}
				}
			});
		}

		(this.invoiceForm.get('line_items') as FormArray).push(control);

		// move all meta fields to end
		for (let i = this.lineItemsFormArray.controls.length - 1; i >= 0; i--) {
			if (this.lineItemsFormArray.at(i).value.line_item_type_id === LineItemTypeId.META_MEDICAL_AID) {
				const control = this.lineItemsFormArray.at(i);
				this.lineItemsFormArray.removeAt(i);
				this.lineItemsFormArray.push(control);
			}
		}


		if (this.payments.length) {
			control.get('quantity')?.disable();
			control.get('unit_amount')?.disable();
			control.get('discount_rate')?.disable();
		}
		return control;
	}

	public setDpcDiscountRate(lineItemsControl: FormGroup, tariffCodeText: string) {
		if (/(0190|0191|0192|0193|0130)/g.test(tariffCodeText)) {
			lineItemsControl.get('discount_rate')?.setValue(100, { emitEvent: false });
		}
	}

	public resolveICD10Description(code: ICD10CodeModel): string {
		return `[${code.code}] ${code.description}`;
	}

	public resolveNAPPIDescription(code: NappiCodeModel): string {
		return `[${code.product_code}] ${code.full_product_name}`;
	}

	public loadComplete(component: string) {
		this.componentsLoaded[component] = true;
		if (Object.values(this.componentsLoaded).reduce((o, c) => o && c, true)) {
			this.loadingStatus = null;
		}
		if (component === 'account' && this.action === 'create' && this.dpcPatientFlag && !this.dpcPatientFlag.data.in_arrears) {
			(this.invoiceForm.get('line_items') as FormArray).controls.forEach(control =>
				this.setDpcDiscountRate(control as FormGroup, (control as FormGroup).get('tariff_code_txt')?.value));
		}
	}

	public removeLineItem(position: number): void {
		(this.invoiceForm.get('line_items') as FormArray).removeAt(position);
	}

	public editDescription(control: AbstractControl) {
		this.activeDescriptionControl = control as FormGroup;
		this.descriptionModalRef = this.modalService.open(this.descriptionModal);
	}

	public overwriteDescription() {
		if (this.activeDescriptionControl) {
			this.activeDescriptionControl.get('description')?.patchValue(
				this.generateDescription(this.activeDescriptionControl.value)
			);
		}
	}

	public async submitInvoice(authorize: boolean, onComplete?: (inv: InvoiceModel) => Observable<any>) {
		if (this.invoiceForm.invalid) {
			this.invoiceForm.markAllAsTouched();
			return;
		}

		// authorize
		if (authorize) {
			this.invoiceForm.get('invoice_status_id')?.patchValue(InvoiceStatusId.AUTHORISED);
		}

		const invoiceData = this.getPreparedInvoiceData();
		this.loadingStateService.start('invoice-save');

		// clear id values if create
		if (this.action === 'create') {
			delete invoiceData.id;
			invoiceData.line_items.forEach((li: any) => delete li['id']);
		}

		invoiceData.line_items = invoiceData.line_items.map((item: any) => {
			return {
				...item,
				tariff_code_id: this.enforceID(item.tariff_code_id),
				icd10_codes: item.icd10_codes ? item.icd10_codes.map((v: number | ICD10CodeModel) => this.enforceID(v)) : [],
				nappi_code_id: this.enforceID(item.nappi_code_id),
			};
		});

		const options = {
			params: {
				include: [
					'currency',
					'caseLifecycle.case',
					'caseLifecycle.stage',
					'caseLifecycle.status',
					'lineItems.nappiCode.nappiManufacturer',
					'lineItems.icd10Codes.icd103Code.icd10Group.icd10Chapter'
				].join(',')
			}
		}
		const actions = {
			create: () => this.invoiceHttpService.create(invoiceData, options),
			update: () => this.invoiceHttpService.update(invoiceData.id, invoiceData, options)
		};

		actions[this.action]().pipe(
			tap(invoice => this.patchForm(invoice)),
			switchMap((invoiceRes: InvoiceModel) => {
				if (onComplete) {
					return onComplete(invoiceRes);
				}
				this.successDialogService.showSuccessDialog(`Invoice ${this.action === 'create' ? 'Created' : 'Saved'} Successfully`)
				if (this.action === 'create') {
					this.router.navigate(['/invoice/update', invoiceRes.id]);
				}
				return of(invoiceRes);
			}),
			take(1),
			catchError(err => {
				this.errorDialogService.showErrorDialogFromResponse(err);
				return throwError(err);
			}),
			finalize(() => this.loadingStateService.end('invoice-save'))
		).subscribe();
	}

	public sendInvoice() {
		if (this.invoiceData) {
			this.invoiceActionService.send(this.invoiceData).pipe(
				take(1),
				catchError(err => {
					this.errorDialogService.showErrorDialogFromResponse(err);
					return throwError(err);
				}),
			).subscribe({
				next: (sent) => {
					if (sent) {
						this.successDialogService.showSuccessDialog(`Invoice Sent Successfully`);
						this.router.navigate(['/invoice/list']);
					}
				}
			});
		}
	}

	public reverseClaim() {
		if (
			this.claimIsSubmitted
		) {

			this.loadingStateService.start('invoice-save');
			this.medikredditClaimHttpService.reversal(this.activeClaimTransaction!.id, {}).pipe(
				take(1),
				switchMap(rev => {
					if (rev.success) {
						this.claimResult = rev;
						this.activeClaimTransaction = rev.claim;
						return of(rev);
					}
					this.errorDialogService.showErrorDialog(rev.message);
					return throwError(() => new Error(rev.message));
				}),
				finalize(() => this.loadingStateService.end('invoice-save'))
			).subscribe();
		}
	}

	public claimInvoice() {
		this.submitInvoice(true, (invoice: InvoiceModel) => {
			let observable: Observable<InvoiceModel> = of(invoice);
			if (
				this.claimIsSubmitted
			) {
				observable = this.medikredditClaimHttpService.reversal(this.activeClaimTransaction!.id, {}).pipe(
					switchMap(rev => {
						if (rev.success) {
							return of(invoice);
						}
						this.errorDialogService.showErrorDialog(rev.message);
						return throwError(() => new Error(rev.message));
					}),
					mapTo(invoice)
				);
			}
			return observable.pipe(
				switchMap(() => {
					return this.medikredditClaimHttpService.create({
						invoice_id: invoice.id
					});
				}),
				tap(res => {
					this.claimResult = res;
					this.activeClaimTransaction = res.claim;
					this.claimModalRef = this.modalService.open(this.claimModal);
				})
			);
		});
	}

	public trackPayment() {
		if (this.invoiceData) {
			this.invoiceActionService.trackPayment(this.invoiceData).pipe(
				take(1)
			).subscribe({
				next: (tracked) => {
					if (tracked) {
						this.successDialogService.showSuccessDialog(`Payment Allocated Successfully`);
						this.init(this.invoiceData?.id as number);
					}
				}
			});
		}
	}

	public lineItemIsVisible(control: AbstractControl) {
		return control.get('line_item_type_id')?.value !== LineItemTypeId.META_MEDICAL_AID;
	}

	private generateDescription(controlValue: any, version: number = 3) {
		return [
			...(version < 3 ? [
				controlValue.line_item_type_id ? this.lineItemTypes.find(({ id }) => id == controlValue.line_item_type_id)?.name : null,
			] : []),
			...(version >= 2 ? [
				controlValue.service_date ? `SERVICE DATE: ${moment(controlValue.service_date).format('YYYY-MM-DD')}` : null,
			] : []),
			...(version >= 1 ? [
				controlValue.tariff_code_json ? `TARIFF:\t${controlValue.tariff_code_json.Code}` : (
					controlValue.tariff_code_txt ? `TARIFF:\t${controlValue.tariff_code_txt}` : null
				),
				controlValue.icd10_codes && controlValue.icd10_codes.length ? `ICD10:\t${controlValue.icd10_codes.map(
					(c: ICD10CodeModel) => this.resolveICD10Description(c)
				).join('\n\t\t')}` : null,
				controlValue.nappi_code_id ? `NAPPI:\t${this.resolveNAPPIDescription(controlValue.nappi_code_id)}` : null,
			] : [])
		].filter(Boolean).join('\n');
	}

	private resolveDescription(controlValue: any) {
		let descriptionString = controlValue.description ? controlValue.description.trim() : '';

		const serviceDateString = controlValue.service_date ? `SERVICE DATE: ${moment(controlValue.service_date).format('YYYY-MM-DD')}` : '';
		const nappiCodeString = controlValue.nappi_code_id ? `NAPPI:\t${this.resolveNAPPIDescription(controlValue.nappi_code_id)}` : '';
		const tariffCodeString = controlValue.tariff_code_txt ? `TARIFF:\t${controlValue.tariff_code_txt}` : '';
		const icd10CodesString = controlValue.icd10_codes && controlValue.icd10_codes.length ? `ICD10:\t${controlValue.icd10_codes.map(
			(c: ICD10CodeModel) => this.resolveICD10Description(c)
		).join('\n\t\t')}` : '';

		// Step 1: Strip old line type headers if its an old invoice
		descriptionString = descriptionString.replace(/^(?:Consumable|Procedure|Modifier|Medicine|Meta_medical_aid){1}\s+/ig, '');

		// start with blank prepend string, we append each match in specific order to make sure our data is always consistently ordered
		let compiledStrings: string[] = [];

		// Step 2: Replace service date line
		if (/\s*SERVICE DATE:?\s*(?:\d{4}-\d{2}-\d{2}|Invalid date)(\s*)/.test(descriptionString)) {
			// strip out the existing date to be replaced
			descriptionString = descriptionString.replace(
				/\s*SERVICE DATE:?\s*(?:\d{4}-\d{2}-\d{2}|Invalid date)(\s*)/g, '$1'
			);
		}
		// set new string to append on
		if (serviceDateString !== '') compiledStrings.push(serviceDateString);

		// Step 3: Replace Tariff line
		if (/\s*TARIFF:?\s*[^\n]+(\s*)/.test(descriptionString)) {
			// strip out the existing date to be replaced
			descriptionString = descriptionString.replace(
				/\s*TARIFF:?\s*[^\n]+(\s*)/g, '$1'
			);
		}
		// set new string to append on
		if (tariffCodeString !== '') compiledStrings.push(tariffCodeString);

		// Step 4: Replace icd10 line
		if (/\s*ICD10:?(?:\s*\[?[A-Z]{1}[0-9]{2,}(?:[\.\/]{1}[A-Z0-9]+)?\]?(?: -)? [^\n]+)*(\s*)/.test(descriptionString)) {
			// strip out the existing date to be replaced
			descriptionString = descriptionString.replace(
				/\s*ICD10:?(?:\s*\[?[A-Z]{1}[0-9]{2,}(?:[\.\/]{1}[A-Z0-9]+)?\]?(?: -)? [^\n]+)*(\s*)/gm, '$1'
			);
		}
		// set new string to append on
		if (icd10CodesString !== '') compiledStrings.push(icd10CodesString);

		// Step 3: Replace Nappi line
		if (/\s*NAPPI:?\s*[^\n]+(\s*)/.test(descriptionString)) {
			// strip out the existing date to be replaced
			descriptionString = descriptionString.replace(
				/\s*NAPPI:?\s*[^\n]+(\s*)/g, '$1'
			);
		}
		// set new string to append on
		if (nappiCodeString !== '') compiledStrings.push(nappiCodeString);

		// clear any remaining whitespace on each end
		descriptionString = descriptionString.trim();

		if (compiledStrings.length > 0) {
			controlValue.description = compiledStrings.join('\n') + (descriptionString.length ? `\n\n${descriptionString}` : '');
		}

		return controlValue.description;
	}

	private initInvoiceForm() {
		this.invoiceForm = this.fb.group({
			id: [null],
			practitioner_id: [null, Validators.required],
			practitioner_role_id: [null, Validators.required],
			account_id: [null, Validators.required],
			case_lifecycle_id: [null],
			invoice_date: [new Date(), Validators.required],
			due_date: [new Date(), Validators.required],
			invoice_status_id: [null],
			line_items: this.fb.array([], [Validators.required]),
		});
	}

	private enforceID(item?: null | number | { id?: number }) {
		if (item && typeof item !== 'number' && typeof item['id'] !== 'undefined') {
			return item.id;
		}
		return item;
	};

	private patchForm(invoice: InvoiceModel) {
		this.initInvoiceForm();
		this.invoiceData = invoice;
		this.currency = invoice.currency;
		this.accountId = invoice.account_id;
		this.caseId = invoice.caseLifecycle?.case_id;
		this.lifecycleId = invoice.caseLifecycle?.id;
		// NB: do this LAST
		this.practitionerId = invoice.practitioner_id;
		this.practitionerRoleId = invoice.practitioner_role_id;
		this.invoiceForm.patchValue({
			id: invoice.id,
			practitioner_id: invoice.practitioner_id,
			practitioner_role_id: invoice.practitioner_role_id,
			account_id: invoice.account_id,
			case_lifecycle_id: invoice.case_lifecycle_id,
			invoice_status_id: invoice.invoice_status_id,
			invoice_date: moment(invoice.invoice_date).toDate(),
			due_date: moment(invoice.due_date).toDate(),
		});
		// can only edit in draft
		if (invoice.invoice_status_id !== InvoiceStatusId.DRAFT) {
			this.invoiceForm.get('invoice_date')?.disable();
			this.invoiceForm.get('case_lifecycle_id')?.disable();
		}
		if (invoice.lineItems) {
			invoice.lineItems.forEach(item => {
				this.addLineItem({
					id: item.id,
					service_date: item.service_date,
					line_item_type_id: item.line_item_type_id,
					// codes
					tariff_code_txt: item.tariff_code_txt,
					tariff_code_json: item.tariff_code_json,
					// tariff_code_id: item.tariffCode, // @TODO: tariffs
					icd10_codes: item.icd10Codes,
					nappi_code_id: item.nappiCode,
					// meta
					description: item.description,
					quantity: item.quantity,
					discount_rate: item.discount_rate,
					unit_amount: item.unit_amount,
				});
			});
		}

		// if its a view only state
		if (InvoiceStatusId.PAID === invoice.invoice_status_id) {
			this.invoiceForm.disable();
		}
	}

	private async initFromParams(options: string) {
		const params: InvoiceUrlParams = JSON.parse(atob(options));
		let accountId = params.accountId;
		if (!params.accountId && params.patientId) {
			const { data } = await this.accountHttpService.list({
				params: {
					'filter[patient_id]': `${params.patientId}`
				}
			}).toPromise();
			accountId = data.length > 0 ? data[0].id : undefined;
			if (!accountId) {
				// no account found, but we want to bill a patient, redirect to account create
				this.router.navigate(['/account', 'create'], {
					queryParams: {
						options: btoa(JSON.stringify({ patientId: params.patientId })),
						redirect: '/invoice/create?options=' + options
					}
				});
				return;
			}
		}
		this.accountId = accountId;
		this.caseId = params.caseId;
		this.lifecycleId = params.lifecycleId;
		// NB: do this LAST
		this.practitionerId = params.practitionerId;
		this.practitionerRoleId = params.practitionerRoleId;
		const invoice_date = params.invoiceDate ? moment(params.invoiceDate).toDate() : new Date();
		this.invoiceForm.patchValue({
			practitioner_id: this.practitionerId,
			practitioner_role_id: this.practitionerRoleId,
			account_id: accountId,
			case_lifecycle_id: params.lifecycleId,
			invoice_status_id: InvoiceStatusId.DRAFT,
			invoice_date,
			due_date: params.dueDate ? moment(params.dueDate).toDate() : invoice_date,
		});

		if (params.presets) {
			this.loadingStatus = 'Loading Line Items Presets';
			let lineItems: LineItemObject[] = [];
			await Promise.all(
				params.presets.map(async (preset: BillablePreset) => {
					const data = await this.billablePresetService.getLineItems(preset);
					lineItems = [...lineItems, ...data];
				})
			);
			lineItems.forEach(item => {
				this.addLineItem({
					id: item.id,
					service_date: invoice_date,
					line_item_type_id: item.line_item_type_id,
					// codes
					tariff_code_txt: item.tariff_code_txt,
					// tariff_code_id: item.tariffCode, // @TODO: tariffs
					icd10_codes: item.icd10_codes,
					nappi_code_id: item.nappi_code_id,
					// meta
					description: item.description,
					quantity: item.quantity,
					unit_amount: item.unit_amount,
				}).updateValueAndValidity(); // want to trigger the valueChanges subscription here
			});
		}
	}

	private init(invoiceId: number) {
		this.invoiceLoaded = false;
		this.loadingStatus = 'Loading Invoice Data';
		forkJoin([
			this.invoiceHttpService.find(invoiceId, {
				params: {
					include: [
						'currency',
						'caseLifecycle.case',
						'caseLifecycle.stage',
						'caseLifecycle.status',
						'lineItems.nappiCode.nappiManufacturer',
						'lineItems.icd10Codes.icd103Code.icd10Group.icd10Chapter'
					].join(',')
				}
			}),
			this.invoicePaymentHttpService.listAll(invoiceId, {
				params: { include: 'items' }
			}),
			this.claimTransactionHttpService.listAll(invoiceId, {
				params: { include: 'items.lineItem,items.codes' }
			})
		]).pipe(
			take(1),
			tap(([invoice, payments, transactions]) => {
				this.payments = payments;
				this.patchForm(invoice);
				if (payments.length) {
					// dates cannot be changed on an invoice with payments allocated to it
					this.invoiceForm.get('invoice_date')?.disable();
					this.invoiceForm.get('case_lifecycle_id')?.disable();
				}
				this.activeClaimTransaction = transactions.reduce<ClaimTransactionModel | undefined>(
					(o, v) => o ? (v.id > o.id ? v : o) : v,
					undefined
				);
			}), // load data
			finalize(() => {
				this.invoiceLoaded = true;
				this.loadingStatus = 'Loading Patient Information';
			})
		).subscribe();
	}

	private getPreparedInvoiceData() {
		let invoiceData = this.invoiceForm.getRawValue();
		invoiceData = this.appendMedicalAidMeta(invoiceData);
		return invoiceData;
	}

	private appendMedicalAidMeta(invoiceData: any) {
		const idx = invoiceData.line_items.findIndex((row: { line_item_type_id: number; }) => row.line_item_type_id === LineItemTypeId.META_MEDICAL_AID);

		let medicalAidInfoItem = {};
		if (idx !== -1) {
			// grab the existing one if present
			[medicalAidInfoItem] = invoiceData.line_items.splice(idx, 1);
		} else {
			medicalAidInfoItem = {
				...medicalAidInfoItem, // push in all ID/GUID values
				// set nulls
				tariff_code_txt: null,
				nappi_code_id: null,
				quantity: null,
				unit_amount: null,
				// set type static
				line_item_type_id: LineItemTypeId.META_MEDICAL_AID,
				// meta
				description: [
					`Medical Aid Plan: ${this.account?.medicalAidOption?.medicalAidPlan?.medicalAidScheme?.name || 'CASH'}`,
					`Member Number: ${this.account?.medical_aid_number || 'N/A'}`,
					`Patient Attended: ${this.account?.patient ? [this.account?.patient.first_name, this.account?.patient.last_name].filter(Boolean).join(' ') : 'N/A'}`,
					`Dependant Code: ${this.account?.dependant_code || 'N/A'}`,
				].join('\n'),
			}
		}

		invoiceData.line_items = [
			...invoiceData.line_items,
			medicalAidInfoItem, // append
		];

		return invoiceData;
	}
}
