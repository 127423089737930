<div class="row justify-content-center">
    <div class="col-md-6">
        <h1 class="card-title text-epione text-uppercase font-weight-bold">Search Invoice</h1>
    </div>
    <div class="col-md-6">
        <a class="btn btn-primary float-right" routerLink="/invoice/create" routerLinkActive="active" role="button">
            + Create Invoice
        </a>
    </div>
    <div class="col-12">
        <hr>
    </div>
    <div class="col-md-12 col-lg-10">
        <!-- TBA - not important for timelines -->
        <!-- <div class="row">
            <div class="col-md-12 d-flex justify-content-center mb-3">
                <button class="btn bg-epione text-light px-5 btn-sm">Search Today`s Invoice</button>
            </div>
        </div> -->
        <div class="row">
            <div class="col-md-6">
                <div class="form-group row">
                    <label for="date-from" class="col-sm-3 col-form-label">Date Created From</label>
                    <div class="col-sm-9">
                        <div class="input-group mb-2 mr-sm-2">
                            <input type="text" class="form-control"
                                   epioneLoadingState="invoice-search"
                                   ngbDatepicker
                                   #fromDate="ngbDatepicker"
                                   id="date-from"
                                   [(ngModel)]="filterStartDate">
                            <div class="input-group-prepend" (click)="fromDate.toggle()">
                                <div class="input-group-text">
                                    <span class="material-icons">calendar_today</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group row">
                    <label for="date-to" class="col-sm-3 col-form-label">Date Created To</label>
                    <div class="col-sm-9">
                        <div class="input-group mb-2 mr-sm-2">
                            <input type="text" class="form-control"
                                   epioneLoadingState="invoice-search"
                                   ngbDatepicker
                                   #toDate="ngbDatepicker"
                                   id="date-to"
                                   [(ngModel)]="filterEndDate">
                            <div class="input-group-prepend" (click)="toDate.toggle()">
                                <div class="input-group-text">
                                    <span class="material-icons">calendar_today</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <!-- @TODO: Medical Aid -->
            <!-- <div class="col-md-6">
                <div class="form-group row">
                    <label for="invoice-type" class="col-sm-3 col-form-label">Payment Type</label>
                    <div class="col-sm-9">
                        <select id="invoice-type"
                                class="form-control"
                                epioneLoadingState="invoice-search"
                                [(ngModel)]="filters.invoice_type_id">
                            <option value="" selected disabled>Select type</option>
                            <option *ngFor="let type of invoiceTypes" [value]="type.id">
                                {{ type.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group row">
                    <label for="invoice-scheme" class="col-sm-3 col-form-label">Scheme</label>
                    <div class="col-sm-9">
                        <select id="invoice-scheme"
                                class="form-control"
                                epioneLoadingState="invoice-search"
                                [(ngModel)]="filters.medical_aid_scheme_id">
                            <option value="" selected disabled>Select scheme</option>
                            <option *ngFor="let scheme of medicalAidSchemes" [value]="scheme.id">
                                {{ scheme.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </div> -->
            <div class="col-md-6">
                <div class="form-group row">
                    <label for="account-number" class="col-sm-3 col-form-label">Account Number</label>
                    <div class="col-sm-9">
                        <input type="text" class="form-control"
                               epioneLoadingState="invoice-search"
                               id="account-number"
                               [(ngModel)]="filters.account_number">
                    </div>
                </div>
            </div>
            <!-- @TODO: Medical Aid -->
            <!-- <div class="col-md-6">
                <div class="form-group row">
                    <label for="member-number" class="col-sm-3 col-form-label">Medical Aid Number</label>
                    <div class="col-sm-9">
                        <input type="text" class="form-control"
                               epioneLoadingState="invoice-search"
                               [(ngModel)]="filters.medical_aid_number"
                               id="member-number">
                    </div>
                </div>
            </div> -->
            <!-- Hidden until we've resolved searchable ID/Passport -->
            <!-- <div class="col-md-6">
                <div class="form-group row">
                    <label for="id-number" class="col-sm-3 col-form-label">ID Number</label>
                    <div class="col-sm-9">
                        <input type="text" class="form-control"
                               epioneLoadingState="invoice-search"
                               [(ngModel)]="filters.passport_number"
                               id="id-number">
                    </div>
                </div>
            </div> -->
            <div class="col-md-6">
                <div class="form-group row">
                    <label for="patient-name" class="col-sm-3 col-form-label">Patient Name</label>
                    <div class="col-sm-9">
                        <input type="text" class="form-control"
                               epioneLoadingState="invoice-search"
                               [(ngModel)]="search"
                               id="patient-name">
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group row">
                    <label for="invoice-number"
                           class="col-sm-3 col-form-label d-flex align-items-center flew-row information-label">
                        Invoice Number
                        <span class="material-icons" placement="bottom"
                              ngbTooltip="To search by invoice number, enter either the full invoice number (e.g. INV-0001) or enter asterisk with the last 2 digits (e.g. *01)">info</span>
                    </label>
                    <div class="col-sm-9">
                        <input type="text" class="form-control"
                               epioneLoadingState="invoice-search"
                               [(ngModel)]="filters.invoice_number"
                               id="invoice-number">
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 d-flex justify-content-end">
                <button class="btn btn-outline-primary px-5 mr-3" (click)="resetFilters()">Clear</button>
                <button class="btn btn-primary px-5" (click)="reload(1)">Search</button>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <table class="table table-hover mt-5">
            <thead>
                <tr class="text-epione text-uppercase">
                    <th scope="col">Patient</th>
                    <th scope="col">Account Number</th>
                    <th scope="col" epioneSortable="invoice_number" (sort)="sort($event)">Invoice Number</th>
                    <th scope="col" epioneSortable="created_at" (sort)="sort($event)">Date Created</th>
                    <th scope="col" epioneSortable="updated_at" (sort)="sort($event)">Last Updated</th>
                    <th scope="col" epioneSortable="invoice_date" (sort)="sort($event)">Invoice Date</th>
                    <th scope="col">Status</th>
                    <th scope="col">Amount Outstanding</th>
                    <th *ngIf="useMediKredit" scope="col">Outstanding from Scheme</th>
                    <!-- Need to test on claims/transactions table if the invoice had been successfully sent through medikredit-->
                    <th *ngIf="useMediKredit" scope="col">Outstanding from Patient</th>
                    <!-- Need to test on claims/transactions table if the invoice had been successfully sent through medikredit-->
                    <th scope="col">Actions</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="invoices; else invoicesLoading">
                    <tr *ngFor="let invoice of invoices">
                        <th>{{invoice.account?.patient?.first_name}} {{invoice.account?.patient?.last_name}}</th>
                        <td>{{invoice.account?.account_number}}</td>
                        <td>{{invoice.invoice_number}}</td>
                        <td>{{invoice.created_at | date}}</td>
                        <td>{{invoice.updated_at | date}}</td>
                        <td>{{invoice.invoice_date | date}}</td>
                        <td>
                            {{
                            invoice.invoice_status_id === INVOICE_AUTHORIZED && invoice.sent
                            ? 'Sent'
                            : invoice.status.name
                            }}
                        </td>
                        <td>{{invoice.amount_due | currency:invoice.currency}}</td>
                        <td *ngIf="useMediKredit">
                            <ng-container
                                          *ngIf="invoice.claimOutstanding && invoice.claimOutstanding.claim; else noClaimScheme">
                                {{invoice.claimOutstanding.scheme | currency:invoice.currency}}
                            </ng-container>
                            <ng-template #noClaimScheme>
                                N/A
                            </ng-template>
                        </td>
                        <td *ngIf="useMediKredit">
                            <ng-container *ngIf="invoice.claimOutstanding; else noClaimPatient">
                                {{invoice.claimOutstanding.patient | currency:invoice.currency}}
                            </ng-container>
                            <ng-template #noClaimPatient>
                                {{invoice.amount_due | currency:invoice.currency}}
                            </ng-template>
                        </td>
                        <td>
                            <div class="action-dropdown" ngbDropdown>
                                <a class="nav-link" tabindex="0" ngbDropdownToggle id="actionDropdown" role="button">
                                    &bull;&bull;&bull;
                                </a>
                                <div ngbDropdownMenu aria-labelledby="actionDropdown"
                                     class="dropdown-menu dropdown-menu-right">
                                    <a ngbDropdownItem
                                       [routerLink]="['/invoice/update', invoice.id]">
                                        <ng-container *epioneInvoiceComplete="invoice.invoice_status_id">
                                            View
                                        </ng-container>
                                        <ng-container *epioneInvoiceIncomplete="invoice.invoice_status_id">
                                            View/Edit
                                        </ng-container>
                                    </a>
                                    <div class="dropdown-divider" *ngIf="invoice.amount_due > 0"></div>
                                    <button ngbDropdownItem
                                            *epioneInvoiceIncomplete="invoice.invoice_status_id"
                                            (click)="authorizeInvoice(invoice)">
                                        Authorize
                                    </button>
                                    <ng-container *epioneInvoiceComplete="invoice.invoice_status_id">
                                        <button ngbDropdownItem
                                                (click)="sendInvoice(invoice)">
                                            {{ invoice.sent ? 'Re-Send/Print' : 'Send/Print' }}
                                        </button>
                                        <button ngbDropdownItem
                                                *ngIf="invoice.amount_due > 0"
                                                (click)="trackPayment(invoice)">
                                            Allocate Payment
                                        </button>
                                    </ng-container>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-container>
                <ng-template #invoicesLoading>
                    <tr>
                        <td colspan="9">
                            <p class="text-center">{{loading ? 'Loading Invoices...' : 'No Invoices To Display'}}</p>
                        </td>
                    </tr>
                </ng-template>
            </tbody>
        </table>
    </div>
    <div class="col-12">
        <epione-pagination [pagination]="pagination" (change)="reload($event)"></epione-pagination>
    </div>
</div>