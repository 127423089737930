<div class="container-fluid">
    <div class="row mb-4">
        <div class="col">
            <h1 class="text-epione text-uppercase font-weight-bold">Billing Email Settings</h1>
            <p>Assign email addresses to receive billing related emaiils that are system generated</p>
        </div>
    </div>
    <ng-container *ngIf="!loading; else loadingTmpl">
        <div class="row card card-white p-3" [formGroup]="form">
            <div class="col-12">
                <h3 class="text-epione text-uppercase font-weight-bold">Payment Notifications</h3>
                <p>Add email recipients for medical claim payment notifications. When a payment response is sent from a
                    medical scheme rearding a claim submission, these email recipients will be notified</p>
            </div>
            <div class="col-12 mb-3" formArrayName="emails">
                <div class="row mb-2" *ngFor="let control of formArray.controls; let i = index">
                    <div class="col-md-6 col">
                        <input type="text" class="form-control" [formControlName]="i"
                               epioneLoadingState="claim-email-save" />
                    </div>
                    <div class="col-auto">
                        <button class="btn btn-danger" (click)="removeRow(i)"
                                epioneLoadingState="claim-email-save">Remove</button>
                    </div>
                </div>
            </div>
            <div class="col-12 mb-3">
                <button class="btn btn-primary" (click)="addRow()" epioneLoadingState="claim-email-save">+ Add
                    Email</button>
            </div>
            <div class="col-12 mb-3">
                <hr>
                <button class="btn btn-primary" (click)="save()" epioneLoadingState="claim-email-save">Save
                    Changes</button>
            </div>
        </div>
    </ng-container>
    <ng-template #loadingTmpl>
        <div>
            <loader message="Loading Email Settings..."></loader>
        </div>
    </ng-template>
</div>