import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP_CONFIG } from '@epione/shared/config/common.config';
import { ErrorDialogService } from '@epione/shared/dialogs/error-dialog.service';
import { PracticeModel } from '@epione/shared/models/practice.model';
import { CallsAPI } from '@epione/shared/services/abstract/http/calls-api';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MedikreditHttpService extends CallsAPI<any, any> {
  protected resourcePath = 'medikredit'
  protected apiBasePath = APP_CONFIG.API_URL.billing;

  constructor(
    protected http: HttpClient,
    protected errorDialogService: ErrorDialogService
  ) {
    super(errorDialogService);
  }

  public check(accountId: number): Observable<any> {
    return this.http.post<any>(this.buildAction('eligibility'), {
      account_id: accountId,
    }, this.buildOptions({})).pipe(
      catchError(err => {
        return throwError(this.handleValidationErrors(err));
      })
    );
  }

  public checkFamily(accountId: number): Observable<any> {
    return this.http.post<any>(this.buildAction('eligibility'), {
      account_id: accountId,
      famcheck: true
    }, this.buildOptions({})).pipe(
      catchError(err => {
        return throwError(this.handleValidationErrors(err));
      })
    );
  }

  public claim(invoiceId: number): Observable<PracticeModel> {
    return this.http.post<PracticeModel>(this.buildAction('claim'), {
      invoice_id: invoiceId
    }, this.buildOptions({})).pipe(
        catchError(err => {
          return throwError(this.handleValidationErrors(err));
        })
      );
  }
  
}