<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand">
        <img class="logo"
             src="/assets/images/epione-net-logo-2-copy-2.svg"
             alt="epione.net" />
    </a>

    <div class="collapse navbar-collapse" id="navbar-primary">
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
            <ng-container *ngIf="showMenu">
                <!-- <li class="nav-item">
                    <a class="nav-link" routerLinkActive="active" routerLink="dashboard">
                        DASHBOARD
                    </a>
                </li> -->
                <li class="nav-item">
                    <a class="nav-link" routerLink="account/list" routerLinkActive="active" role="button">
                        ACCOUNTS
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="invoice/list" routerLinkActive="active" role="button">
                        INVOICES
                    </a>
                </li>
                <li class="nav-item" ngbDropdown *ngIf="canAccessSettings">
                    <a class="nav-link" ngbDropdownToggle id="settingsDropdown" role="button">
                        SETTINGS
                    </a>
                    <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="settingsDropdown">
                        <a ngbDropdownItem class="dropdown-item" routerLink="/settings/account-mapping"
                           routerLinkActive="active">
                            Billing Account Mappings
                        </a>
                        <a ngbDropdownItem class="dropdown-item" routerLink="/settings/members"
                           routerLinkActive="active">
                            Practice Member Preferences
                        </a>
                        <a ngbDropdownItem class="dropdown-item" routerLink="/settings/medical-aid-practice"
                           routerLinkActive="active">
                           Medical Insurance Practice Settings
                        </a>
                        <a ngbDropdownItem class="dropdown-item" routerLink="/settings/basket"
                           routerLinkActive="active">
                            Basket Settings
                        </a>
                        <a ngbDropdownItem class="dropdown-item" routerLink="/settings/billing-email"
                           routerLinkActive="active">
                           Billing Email Settings 
                        </a>
                        <!-- <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="#">Default Values</a> -->
                    </div>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link" routerLinkActive="active"routerLink="reports">
                        REPORTS
                    </a>
                </li> -->
            </ng-container>
        </ul>
        <ul class="navbar-nav navbar-right mt-2 mt-lg-0">
            <li class="nav-item">
                <a class="nav-link" [href]="mainSystemLink">&lt; BACK TO MY PRACTICE</a>
            </li>
        </ul>
    </div>
</nav>