import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InvoiceListComponent } from '@epione/modules/invoice/invoice-list/invoice-list.component';
import { InvoiceRoutingModule } from '@epione/modules/invoice/invoice-routing.module';
import { InvoiceSaveComponent } from '@epione/modules/invoice/invoice-save/invoice-save.component';
import { InvoiceViewComponent } from '@epione/modules/invoice/invoice-view/invoice-view.component';
import { InvoiceComponent } from '@epione/modules/invoice/invoice.component';
import { SharedModule } from '@epione/shared/shared.module';
import { NgbDatepickerModule, NgbDropdownModule, NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectAccountComponent } from './invoice-save/select-account/select-account.component';
import { SelectPractitionerComponent } from './invoice-save/select-practitioner/select-practitioner.component';
import { SelectCaseComponent } from './invoice-save/select-case/select-case.component';
import { SelectIcd10Component } from './invoice-save/select-icd10/select-icd10.component';
import { SelectNappiComponent } from './invoice-save/select-nappi/select-nappi.component';
import { SendInvoiceModalComponent } from './components/send-invoice-modal/send-invoice-modal.component';
import { AuthorizeInvoiceModalComponent } from './components/authorize-invoice-modal/authorize-invoice-modal.component';
import { TrackPaymentModalComponent } from './components/track-payment-modal/track-payment-modal.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SelectTariffComponent } from './invoice-save/select-tariff/select-tariff.component';
@NgModule({
    declarations: [
        InvoiceComponent,
        InvoiceSaveComponent,
        InvoiceListComponent,
        InvoiceViewComponent,
        SelectAccountComponent,
        SelectPractitionerComponent,
        SelectCaseComponent,
        SelectIcd10Component,
        SelectNappiComponent,
        SelectTariffComponent,
        SendInvoiceModalComponent,
        AuthorizeInvoiceModalComponent,
        TrackPaymentModalComponent
    ],
    exports: [
        SelectIcd10Component,
        SelectNappiComponent,
        SelectTariffComponent
    ],
    imports: [
        CommonModule,
        InvoiceRoutingModule,
        SharedModule,
        NgbDropdownModule,
        ReactiveFormsModule,
        NgbDatepickerModule,
        NgSelectModule,
        FormsModule,
        NgbModalModule,
        NgbTooltipModule,
        DragDropModule
    ]
})
export class InvoiceModule { }
