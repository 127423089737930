export enum BillingClaimStatusAlias {
    REQUESTED = 'requested',
    APPROVED = 'approved',
    NOT_APPLICABLE = 'not_applicable',
    PENDING = 'pending',
    SUBMITTED = 'submitted',
    REJECTED = 'rejected',
    REVERSED = 'reversed',
    PROCESSED = 'processed',
    DUPLICATED = 'duplicate',
}

export enum BillingClaimStatusId {
    REQUESTED = 1,
    APPROVED = 2,
    NOT_APPLICABLE = 3,
    PENDING = 4,
    SUBMITTED = 5,
    REJECTED = 6,
    REVERSED = 7,
    PROCESSED = 8,
    DUPLICATED = 9
}