import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { InvoiceModel } from '@epione/shared/models/invoice.model';
import { MedicalAidSchemeModel } from '@epione/shared/models/main/medical-aid-scheme.model';
import { catchError, finalize, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { InvoiceHttpService } from '../services/http/invoice-http.service';
import * as moment from 'moment';
import { MedicalAidSchemesService } from '@epione/shared/services/main/medical-aid/medical-aid-schemes.service';
import { Pagination } from '@epione/shared/types/paginatedResponse';
import { of, Subject, throwError } from 'rxjs';
import { SortableDirective, SortEvent } from '@epione/shared/directives/sortable.directive';
import { InvoiceActionService } from '../services/invoice-action.service';
import { ErrorDialogService } from '@epione/shared/dialogs/error-dialog.service';
import { SuccessDialogService } from '@epione/shared/dialogs/success-dialog.service';
import { InvoiceStatusId } from '@epione/shared/types/invoice-status';
import { PracticeModel } from '@epione/shared/models/practice.model';
import { MedikreditHttpService } from '@epione/shared/services/main/medikredit.service';
import { PracticeService } from '@epione/modules/settings/medical-aid-practice/services/http/practice.service';
import { MedikreditEligibilityHttpService } from '@epione/modules/account/services/medikredit-eligibility-http.service';

@Component({
  selector: 'epione-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent implements OnInit, OnDestroy {
  public INVOICE_AUTHORIZED: number = InvoiceStatusId.AUTHORISED;

  @ViewChildren(SortableDirective) sortableColumns!: QueryList<SortableDirective>;
  public medicalAidSchemes: MedicalAidSchemeModel[] = [];
  public practice?: PracticeModel;
  public loading: boolean = true;
  public invoices?: InvoiceModel[];
  public pagination?: Pagination;
  public filters!: { [key: string]: string | null | Date | Array<string | null | Date> };
  public order: SortEvent = { column: 'updated_at', direction: 'desc' };
  public search: string = '';
  private options: any = {
    page: 1,
    per_page: 15,
    include: 'account.patient,account.medicalAidOption.medicalAidPlan.medicalAidScheme,claimOutstanding',
  };
  private reload$: Subject<void> = new Subject<void>();
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private medikreditHttpService: MedikreditHttpService,
    private invoiceHttpService: InvoiceHttpService,
    private practiceHttpService: PracticeService,
    private medicalAidSchemeHttpService: MedicalAidSchemesService,
    private invoiceActionService: InvoiceActionService,
    private errorDialogService: ErrorDialogService,
    private successDialogService: SuccessDialogService
  ) {
    this.resetFilters();
  }

  ngOnInit(): void {
    this.practiceHttpService.find().pipe(
      take(1)
    ).subscribe({
      next: (res) => this.practice = res as PracticeModel
    });
    this.medicalAidSchemeHttpService.listAll().pipe(
      take(1),
    ).subscribe({
      next: (res) => this.medicalAidSchemes = res
    })
    this.reload$.pipe(
      takeUntil(this.unsubscribe$),
      switchMap(() => this.loadData())
    ).subscribe();
    this.reload();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  set filterStartDate(date: null | Date) {
    (this.filters.created_at! as Array<null | Date>)[0] = date;
  }
  set filterEndDate(date: null | Date) {
    (this.filters.created_at! as Array<null | Date>)[1] = date;
  }

  get filterStartDate(): null | Date {
    return (this.filters.created_at! as Array<null | Date>)[0];
  }
  get filterEndDate(): null | Date {
    return (this.filters.created_at! as Array<null | Date>)[1];
  }

  get useMedikredit(): boolean {
    return !!this.practice?.use_medikredit;
  }

  public resetFilters() {
    this.filters = {
      account_number: null,
      passport_number: null,
      medical_aid_number: null,
      invoice_number: null,
      created_at: [null, null]
    };
  }
  public sort(event: SortEvent) {
    // reset other columns
    this.sortableColumns.filter(s => !s.is(event)).forEach(s => s.reset())
    this.order = event;
    this.reload();
  }

  public reload(page?: number) {
    if (page) {
      this.options.page = page;
    }
    this.loading = true;
    // clear state
    this.invoices = undefined;
    this.pagination = undefined;
    this.reload$.next();
  }

  loadData() {
    return this.invoiceHttpService.list({
      params: {
        ...this.options,
        ...(this.search !== '' ? { search: this.search } : {}),
        ...{ order: this.order.column + ',' + this.order.direction },
        ...Object.keys(this.filters).reduce<{ [key: string]: string }>((out, key) => {
          if (Array.isArray(this.filters[key])) {
            // must have all elements
            if ((this.filters[key] as any[]).filter(Boolean).length === (this.filters[key] as any[]).length) {
              out[`filter[${key}]`] = (this.filters[key] as Array<string | Date>).map(v => {
                return v instanceof Date ? v.toISOString() : v;
              }).join(',');
            }
          } else if (!!this.filters[key] && this.filters[key] !== '') {
            out[`filter[${key}]`] = this.filters[key] as string;
          }
          return out;
        }, {})
      }
    }).pipe(
      finalize(() => this.loading = false),
      take(1),
      tap((res) => {
        this.invoices = res.data;
        this.pagination = res.meta.pagination;
      })
    );
  }

  public authorizeInvoice(invoice: InvoiceModel) {
    this.invoiceActionService.authorize(invoice).pipe(
      take(1),
      switchMap((authorized) => {
        // Ref: EPN-2169
        // if (authorized) {
        //   return this.invoiceActionService.send(invoice).pipe(map(sent => [authorized, sent]));
        // }
        return of([authorized, false]);
      }),
      catchError(err => {
        this.errorDialogService.showErrorDialogFromResponse(err);
        return throwError(err);
      }),
    ).subscribe({
      next: ([authorized, sent]) => {
        if (authorized) {
          this.successDialogService.showSuccessDialog(`Invoice Authorized${sent ? ' & Sent' : ''} Successfully`);
          this.reload();
        }
      }
    });
  }


  public sendInvoice(invoice: InvoiceModel) {
    this.invoiceActionService.send(invoice).pipe(
      take(1),
      catchError(err => {
        this.errorDialogService.showErrorDialogFromResponse(err);
        return throwError(err);
      }),
    ).subscribe({
      next: (sent) => {
        if (sent) {
          this.successDialogService.showSuccessDialog(`Invoice Sent Successfully`);
          this.reload();
        }
      }
    });
  }

  public trackPayment(invoice: InvoiceModel) {
    this.invoiceActionService.trackPayment(invoice).pipe(
      take(1)
    ).subscribe({
      next: (tracked) => {
        if (tracked) {
          this.successDialogService.showSuccessDialog(`Payment Allocated Successfully`);
          this.reload();
        }
      }
    });
  }
}
