import { Injectable } from '@angular/core';
import { BillingHttpApiService } from "@epione/shared/services/http/billing-http-api.service";
import { PracticeModel } from "@epione/shared/models/practice.model";
import { PaginatedResponse } from "@epione/shared/types/paginatedResponse";
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { CallsAPI } from '@epione/shared/services/abstract/http/calls-api';
import { APP_CONFIG } from '@epione/shared/config/common.config';
import { HttpClient } from '@angular/common/http';
import { ErrorDialogService } from '@epione/shared/dialogs/error-dialog.service';

@Injectable({
  providedIn: 'root'
})
export class PracticeService extends CallsAPI<PracticeModel, PaginatedResponse<PracticeModel>> {
  protected resourcePath = "settings/practice";
  protected apiBasePath = APP_CONFIG.API_URL.billing;

  constructor(
    protected http: HttpClient,
    protected errorDialogService: ErrorDialogService
  ) {
    super(errorDialogService);
  }

  /**
   * @param {*} body
   * @param {HttpOptions} options
   * @returns
   * @memberof HttpApiService
   */
  public find(): Observable<PracticeModel> {
    return this.http.get<PracticeModel>(this.buildAction(''), this.buildOptions({})).pipe(
      catchError(err => {
        return throwError(this.handleValidationErrors(err));
      })
    );
  }

  public update(practice: Partial<PracticeModel>): Observable<PracticeModel> {
    return this.http.patch<PracticeModel>(this.buildAction(''), practice, this.buildOptions({})).pipe(
      catchError(err => {
        return throwError(this.handleValidationErrors(err));
      })
    );
  }
}
