import Decimal from 'decimal.js';

const DIVISOR = 100;
export const currencyToDecimal = (value: unknown, decimal: number = 2): string => {
    const qty = new Decimal(`${value || 0}`).div(new Decimal(DIVISOR));
    return qty.toFixed(decimal);
}

export const currencyFromDecimal = (value: unknown) => {
    const qty = new Decimal(`${value || 0}`).mul(new Decimal(DIVISOR));
    return qty.toFixed(0);
}