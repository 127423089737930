import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MedicalAidSchemeModel } from "@epione/shared/models/main/medical-aid-scheme.model";
import { MedicalAidSchemesService } from "@epione/shared/services/main/medical-aid/medical-aid-schemes.service";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MedicalAidPracticeService } from "@epione/modules/settings/medical-aid-practice/services/http/medical-aid-practice.service";
import { PracticeService } from "@epione/modules/settings/medical-aid-practice/services/http/practice.service";
import { LoadingStateService } from "@epione/shared/services/global/loading-state.service";
import { SuccessDialogService } from "@epione/shared/dialogs/success-dialog.service";
import { ErrorDialogService } from "@epione/shared/dialogs/error-dialog.service";
import { Pagination } from "@epione/shared/types/paginatedResponse";
import { MedicalAidPracticeModel } from "@epione/shared/models/medical-aid-practice.model";
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    finalize,
    switchMap,
    take,
    takeUntil,
    tap
} from "rxjs/operators";
import { Observable, Subject, throwError } from "rxjs";
import { SortableDirective, SortEvent } from "@epione/shared/directives/sortable.directive";
import { PracticeModel } from '@epione/shared/models/practice.model';
import { ActiveUserService } from '@epione/shared/services/global/active-user.service';
import { USER_ROLE, USER_ROLE_ID } from '@epione/shared/config/roles.config';

interface MedicalPracticeFormModel extends MedicalAidPracticeModel {
    emailControl: FormControl;
}

@Component({
    selector: 'epione-medical-aid-practice',
    templateUrl: './medical-aid-practice.component.html',
    styleUrls: ['./medical-aid-practice.component.scss']
})
export class MedicalAidPracticeComponent implements OnInit {
    @ViewChildren(SortableDirective) sortableColumns!: QueryList<SortableDirective>;
    public medicalAidSchemes: MedicalAidSchemeModel[] = [];
    public settingsFormGroup!: FormGroup;
    public loading: boolean = true;
    public loadingStatus: string = 'Loading Practice Settings';
    public billingMedicalAids!: MedicalPracticeFormModel[];
    public practice: PracticeModel | undefined;
    public pagination?: Pagination;
    public order: SortEvent = { column: 'updated_at', direction: 'desc' };
    public search: string = '';
    public $searchControl: FormControl = new FormControl('');

    private reload$: Subject<void> = new Subject<void>();
    private unsubscribe$: Subject<void> = new Subject<void>();
    private options: any = {
        page: 1,
        per_page: 10,
    };

    constructor(
        private medicalAidSchemesService: MedicalAidSchemesService,
        private fb: FormBuilder,
        private medicalAidPracticeService: MedicalAidPracticeService,
        private practiceService: PracticeService,
        private activeUserService: ActiveUserService,
        private loadingStateService: LoadingStateService,
        private successDialogService: SuccessDialogService,
        private errorDialogService: ErrorDialogService,
    ) {
    }

    get showMedikreditOption() {
        return this.activeUserService.user?.practice.role_id === USER_ROLE_ID.GENERAL_PRACTITIONER;
    }

    ngOnInit(): void {
        this.initSettingsForm();
        this.practice = this.activeUserService.practice!;
        this.medicalAidSchemesService.list({
            params: {
                'filter[country_id]': '202'
            }
        }).toPromise()
            .then((res: MedicalAidSchemeModel[]) => this.medicalAidSchemes = res);
        this.reload$.pipe(
            takeUntil(this.unsubscribe$),
            tap(() => this.loading = true),
            switchMap(() => this.loadData()),
            tap(() => this.loading = false)
        ).subscribe();
        this.reload();
        this.$searchControl.valueChanges
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                tap((term) => {
                    this.search = term;
                    this.loading = true;
                }),
                switchMap(() => this.loadData())
            )
            .subscribe();
    }

    get useMedikredit(): boolean | undefined {
        return !!this.practice?.use_medikredit
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public sort(event: SortEvent) {
        // reset other columns
        this.sortableColumns.filter(s => !s.is(event)).forEach(s => s.reset())
        this.order = event;
        this.reload();
    }

    public loadData() {
        return this.medicalAidPracticeService.list({
            params: {
                ...this.options,
                ...(this.search !== '' ? { search: this.search } : {}),
                ...{ order: this.order.column + ',' + this.order.direction }
            }
        })
            .pipe(
                take(1),
                tap(res => {
                    this.billingMedicalAids = res.data.map(record => {
                        const row = record as MedicalPracticeFormModel;
                        row.emailControl = new FormControl(
                            row.email,
                            [Validators.required, Validators.email]
                        );
                        return row;
                    });
                    this.pagination = res.meta.pagination;
                })
            );
    }

    public addSettings() {
        this.settingsFormGroup.updateValueAndValidity();
        if (this.settingsFormGroup.invalid) {
            this.settingsFormGroup.markAllAsTouched();
            return;
        }

        const observable = this.medicalAidPracticeService.create(this.settingsFormGroup.value)
            .pipe(
                tap((record: MedicalAidPracticeModel) => {
                    this.reload();
                    this.settingsFormGroup.reset();
                })
            );
        this.performAction(observable, 'Medical aid setting created successfully')
    }

    public updateSettings(medicalAidPractice: MedicalPracticeFormModel) {
        if (medicalAidPractice.emailControl.invalid) {
            return
        }
        medicalAidPractice.email = medicalAidPractice.emailControl.value;
        const observable = this.medicalAidPracticeService.update(medicalAidPractice.id, medicalAidPractice);
        this.performAction(observable, 'Medical aid setting updated successfully')
    }

    public removeSettings(id: number) {
        const idx = this.billingMedicalAids.findIndex(row => row.id === id);
        const observable = this.medicalAidPracticeService.delete(id)
            .pipe(
                tap(() => this.billingMedicalAids.splice(idx, 1))
            );
        this.performAction(observable, 'Medical aid setting deleted successfully')
    }

    public async updateMedicalInsuranceClaimProcess() {
        const observable = this.practiceService.update(this.practice!, {
            params: {
                include: 'role'
            }
        });

        this.performActionUpdateClaimProcess(observable, 'Claim process updated')

        observable.pipe(
            take(1),
            tap((res: PracticeModel) => {
                this.practice = res; // Update this.practice with the latest data
                this.activeUserService.practice = res; // persist to active state
            }),
            catchError(err => {
                this.errorDialogService.showErrorDialogFromResponse(err);
                return throwError(err);
            })
        ).subscribe();
    }

    public reload(page?: number) {
        if (page) {
            this.options.page = page;
        }
        this.loading = true;
        // clear state
        this.billingMedicalAids = [];
        this.pagination = undefined;
        this.reload$.next();
    }

    private performAction(observable: Observable<any>, successMessage: string) {
        this.loadingStateService.start('medical-aid-practice-save');
        observable.pipe(
            take(1),
            catchError(err => {
                this.errorDialogService.showErrorDialogFromResponse(err);
                return throwError(err);
            }),
            finalize(() => {
                this.loadingStateService.end('medical-aid-practice-save');
            }),
            tap(() => this.successDialogService.showSuccessDialog(successMessage))
        ).subscribe();
    }

    private async performActionUpdateClaimProcess(observable: Observable<any>, successMessage: string) {
        this.loadingStateService.start('medical-aid-practice-save');
        observable.pipe(
            take(1),
            catchError(err => {
                this.errorDialogService.showErrorDialogFromResponse(err);
                return throwError(err);
            }),
            finalize(() => {
                this.loadingStateService.end('medical-aid-practice-save');
            }),
            tap(() => this.successDialogService.showSuccessDialog(successMessage))
        ).subscribe();
    }

    private initSettingsForm(): void {
        this.settingsFormGroup = this.fb.group({
            medical_aid_scheme_id: [null, Validators.required],
            email: [null, [Validators.email, Validators.required]]
        });
    }
}
