import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP_CONFIG } from '@epione/shared/config/common.config';
import { ErrorDialogService } from '@epione/shared/dialogs/error-dialog.service';
import { MedikreditOptionModel } from '@epione/shared/models/medikredit/option.model';
import { CallsAPI } from '@epione/shared/services/abstract/http/calls-api';
import { BillingHttpApiService } from '@epione/shared/services/http/billing-http-api.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MedikreditOptionHttpService extends CallsAPI<MedikreditOptionModel, MedikreditOptionModel[]> {
  protected resourcePath = 'medikredit/options'
  protected apiBasePath = APP_CONFIG.API_URL.billing;

  constructor(
    protected http: HttpClient,
    protected errorDialogService: ErrorDialogService
  ) {
    super(errorDialogService);
  }

  public list(): Observable<MedikreditOptionModel[]> {
    return this.cachedResponse<MedikreditOptionModel[]>(
      'medikredit-option-list',
      () => this.http.get<MedikreditOptionModel[]>(this.buildAction(''), this.buildOptions({})).pipe(
        catchError(err => {
          return throwError(this.handleValidationErrors(err));
        })
      )
    );
  }
}