
import { Injectable } from '@angular/core';
import { PaginatedResponse } from '@epione/shared/types/paginatedResponse';
import { BillingHttpApiService } from '@epione/shared/services/http/billing-http-api.service';
import { ClaimTransactionItemModel } from '@epione/shared/models/medikredit/claim-transaction-line-item.model';
import { HttpOptions } from '@epione/shared/services/abstract/http/http-api.service';
import { ClaimTransactionModel } from '@epione/shared/models/medikredit/claim-transaction.model';
import { Observable } from 'rxjs';

export interface ClaimResponseModel {
  success: boolean;
  message: string;
  claim: ClaimTransactionModel;
}

@Injectable({
  providedIn: 'root'
})

export class MedikreditClaimHttpService extends BillingHttpApiService<ClaimResponseModel> {
  protected resourcePath = 'medikredit/claim';

  public reversal(parentId: number, body: any, options?: HttpOptions): Observable<ClaimResponseModel> {
    return this.http.post<ClaimResponseModel>(
      this.buildAction('/{:parentId}/reversal', { parentId }),
      body,
      this.buildOptions(options)
    );
  }
}