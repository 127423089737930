import { environment as baseEnv } from './environment.base';
export const environment = {
  ...baseEnv,
  stage: 'dev',
  production: false,
  app_version: 'dev-320',
  api: {
    billing: 'https://api.billing.dev.epione.net',
    main: 'https://api-dev.dev2.epione.net'
  },
  app: {
    billing: 'https://billing-dev.dev2.epione.net',
    main: 'https://app-dev.dev2.epione.net'
  }
};
