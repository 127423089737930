import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { LoadingStateService } from "@epione/shared/services/global/loading-state.service";
import { SuccessDialogService } from "@epione/shared/dialogs/success-dialog.service";
import { ErrorDialogService } from "@epione/shared/dialogs/error-dialog.service";
import { Pagination } from "@epione/shared/types/paginatedResponse";
import {
    catchError,
    delay,
    finalize,
    switchMap,
    take,
    takeUntil,
    tap
} from "rxjs/operators";
import { Observable, Subject, throwError } from "rxjs";
import { SortEvent } from "@epione/shared/directives/sortable.directive";
import { BillingEmailService } from './services/http/billing-email.service';
import { IdEmailModel } from '@epione/shared/models/Id-email.model';
import { ActiveUserService } from '@epione/shared/services/global/active-user.service';
import { PracticeService } from '../medical-aid-practice/services/http/practice.service';

interface BillingEmailFormModel extends IdEmailModel {
    emailControl: FormControl;
}

@Component({
    selector: 'epione-billing-email',
    templateUrl: './billing-email.component.html',
    styleUrls: ['./billing-email.component.scss']
})

export class BillingEmailComponent implements OnInit {

    public loading: boolean = true;
    public form: FormGroup = new FormGroup({
        emails: new FormArray([])
    });

    constructor(
        private practiceService: PracticeService,
        private loadingStateService: LoadingStateService,
        private successDialogService: SuccessDialogService,
        private errorDialogService: ErrorDialogService,
        private activeUser: ActiveUserService
    ) {
    }

    get formArray() {
        return this.form.get('emails') as FormArray;
    }

    ngOnInit(): void {
        this.loadData();
    }

    public loadData() {
        this.loading = true;
        return this.practiceService.find()
            .pipe(
                finalize(() => {
                    this.loading = false; // Stop loading when the observable completes
                }),
                take(1),
                tap(res => {
                    (res.payment_emails || '').split(',').forEach(email => {
                        this.formArray.push(new FormControl(email));
                    });
                })
            ).subscribe();
    }

    public addRow() {
        this.formArray.push(new FormControl(''));
    }

    public save() {
        this.performAction(
            this.practiceService.update({
                payment_emails: this.formArray.value.join(',')
            }).pipe(
                tap(res => {
                    this.formArray.clear();
                    (res.payment_emails || '').split(',').forEach(email => {
                        this.formArray.push(new FormControl(email));
                    });
                })
            ),
            'Billing claim emails updated successfully'
        )
    }

    public removeRow(idx: number) {
        this.formArray.removeAt(idx);
    }

    private performAction(observable: Observable<any>, successMessage: string) {
        this.loadingStateService.start('claim-email-save');
        observable.pipe(
            take(1),
            catchError(err => {
                this.errorDialogService.showErrorDialogFromResponse(err);
                return throwError(err);
            }),
            finalize(() => {
                this.loadingStateService.end('claim-email-save');
            }),
            tap(() => this.successDialogService.showSuccessDialog(successMessage))
        ).subscribe();
    }
}

