import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PAYMENT_METHODS } from "@epione/shared/config/payments";
import { ADDRESS_TYPES } from "@epione/shared/config/addresses.config";
import { MedicalAidSchemesService } from "@epione/shared/services/main/medical-aid/medical-aid-schemes.service";
import { MedicalAidPlansService } from "@epione/shared/services/main/medical-aid/medical-aid-plans.service";
import { MedicalAidOptionsService } from "@epione/shared/services/main/medical-aid/medical-aid-options.service";
import { MedicalAidSchemeModel } from "@epione/shared/models/main/medical-aid-scheme.model";
import { MedicalAidOptionModel } from "@epione/shared/models/main/medical-aid-option.model";
import { MedicalAidPlanModel } from "@epione/shared/models/main/medical-aid-plan.model";
import { LoadingStateService } from '@epione/shared/services/global/loading-state.service';
import { ErrorDialogService } from '@epione/shared/dialogs/error-dialog.service';
import { SuccessDialogService } from '@epione/shared/dialogs/success-dialog.service';
import { AccountsHttpService } from "@epione/modules/account/services/accounts-http.service";
import { AccountsAddressesHttpService } from "@epione/modules/account/services/accounts-addresses-http.service";
import { BehaviorSubject, forkJoin, merge, Observable, of, Subject, throwError } from 'rxjs';
import {
	catchError,
	debounceTime,
	distinctUntilChanged,
	filter,
	finalize,
	first,
	map,
	mapTo,
	mergeMap,
	switchMap,
	take,
	tap
} from 'rxjs/operators';
import { AccountModel } from '@epione/shared/models/account.model';
import { PatientHttpService } from '@epione/shared/services/main/patient.service';
import { PatientModel } from '@epione/shared/models/main/patient.model';
import { Pagination } from '@epione/shared/types/paginatedResponse';
import { BillingAddressModel } from '@epione/shared/models/billing-address.model';
import { AssociateAccountComponent } from '@epione/modules/account/account-save/associate-account/associate-account.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogService } from '@epione/shared/dialogs/confirm-dialog.service';
import { AccountsGuarantorHttpService } from '@epione/modules/account/services/accounts-guarantor-http.service';
import { MedikreditOptionHttpService } from '../services/medikredit-option-http.service';
import { MedikreditOptionModel } from '@epione/shared/models/medikredit/option.model';
import { MedikreditEligibilityHttpService } from '../services/medikredit-eligibility-http.service';
import { ActiveUserService } from '@epione/shared/services/global/active-user.service';

interface PatientSearchResult extends PatientModel {
	isActive: boolean;
	label: string;
	meta: string;
}

@Component({
	selector: 'epione-account-save',
	templateUrl: './account-save.component.html',
	styleUrls: ['./account-save.component.scss']
})
export class AccountSaveComponent implements OnInit {
	public paymentMethods = [
		{
			key: PAYMENT_METHODS.CASH,
			value: 'Cash'
		}, {
			key: PAYMENT_METHODS.MEDICAL_AID,
			value: 'Medical Aid'
		}
	];
	public showMedicalAids = false;
	public action: 'create' | 'update' = 'create';
	public medicalAidSchemes: MedicalAidSchemeModel[] = [];
	public medicalAidPlans: MedicalAidPlanModel[] = [];
	public medicalAidOptions: MedicalAidOptionModel[] = [];
	public medikreditOptions: MedikreditOptionModel[] = [];
	public accountFormGroup!: FormGroup;

	public patientSearchTerm$: Subject<string> = new Subject<string>();
	public patientsLoading: boolean = false;
	public patient: PatientModel | null = null;
	public patients$!: Observable<PatientSearchResult[]>;
	public pagination!: Pagination;
	public accountId?: number;
	public addressId?: number;
	public loadingStatus: string | null = 'Loading';
	public account?: AccountModel;
	public medikreditOptionMatches: MedikreditOptionModel[] = [];

	private redirect: [string[], { queryParams: any }?] = [['/account/list']];
	private defaultItems: BehaviorSubject<PatientSearchResult[]> = new BehaviorSubject<PatientSearchResult[]>([]);
	private confirmSubject: Subject<boolean> = new Subject<boolean>();

	constructor(
		public activeUser: ActiveUserService,
		private fb: FormBuilder,
		private route: ActivatedRoute,
		private medicalAidSchemesService: MedicalAidSchemesService,
		private medicalAidPlansService: MedicalAidPlansService,
		private medicalAidOptionsService: MedicalAidOptionsService,
		private accountsHttpService: AccountsHttpService,
		private accountsAddressesHttpService: AccountsAddressesHttpService,
		private loadingStateService: LoadingStateService,
		private errorDialogService: ErrorDialogService,
		private successDialogService: SuccessDialogService,
		private patientHttpService: PatientHttpService,
		private router: Router,
		private modalService: NgbModal,
		private confirmDialogService: ConfirmDialogService,
		private accountsGuarantorHttpService: AccountsGuarantorHttpService,
		private medikreditOptionHttpService: MedikreditOptionHttpService,
		private medikreditEligibilityHttpService: MedikreditEligibilityHttpService
	) {
		this.initAccountForm();
		this.initFormChangeSubscriptions();
		this.patients$ = merge<PatientSearchResult[]>(
			this.defaultItems.asObservable(), // default items
			this.patientSearchTerm$.pipe(
				debounceTime(1000),
				distinctUntilChanged(),
				tap(() => this.patientsLoading = true),
				switchMap(term => this.patientHttpService.list({
					params: {
						search: term,
						per_page: '20',
						'filter[has_billing_account]': 'false',
						include: 'payment_method,medical_aid_details,phones,addresses'
					}
				}).pipe(
					map(res => {
						this.pagination = res.meta.pagination;
						return res.data.map(pat => ({
							isActive: true,
							label: [pat.first_name, pat.last_name].filter(Boolean).join(' '),
							meta: [pat.email, pat.phone].filter(Boolean).join(', '),
							...pat
						}));
					}),
					catchError(() => of([])), // empty list on error
					tap(() => this.patientsLoading = false)
				))
			)
		)
	}

	ngOnInit(): void {
		this.medicalAidSchemesService.list({
			params: {
				//TODO make country id dynamic.
				'filter[country_id]': '202'
			}
		}).subscribe({
			next: res => this.medicalAidSchemes = res
		});
		const medikreditSub = this.medikreditOptionHttpService.list().subscribe({
			next: res => {
				// currently only care about procedures and consults
				this.medikreditOptions = res.filter(v => !v.is_dispensing);
			}
		});

		this.route.params.subscribe({
			next: res => {
				const params = this.route.snapshot.queryParams;
				if ('redirect' in params && !!params.redirect) {
					const redirect = this.router.parseUrl(params.redirect);
					this.redirect = [
						[redirect.toString().split('?')[0]],
						{ queryParams: redirect.queryParams }
					];
				}
				if ('id' in res && !!res.id) {
					this.accountId = res.id;
					this.action = 'update';
					this.loadAccountData()
				} else {
					if ('options' in params && !!params.options) {
						// options can be base64 serialized options object
						const { patientId } = JSON.parse(atob(params.options));
						this.loadingStatus = 'Fetching Patient Account Records';
						medikreditSub.add(() => {
							this.initFromParams({ patientId }).then(() => {
								this.loadingStatus = null;
							});
						})
					} else {
						this.loadingStatus = null; // kill initial loader
						// queue after digest
						setTimeout(() => this.patientSearchTerm$.next(''), 0);
					}
				}
			}
		});
	}

	get canRunEligibilityCheck() {
		return !!this.account && !!this.account.medikredit_option_id;
	}

	public async runEligibilityCheck() {
		if (!this.canRunEligibilityCheck) return;
		const saved = await this.saveAccount(true);
		if (saved) {
			await this.medikreditEligibilityHttpService.check(this.account!.id).pipe(
				tap(res => {
					if (res.standing) {
						this.account!.medicalAidStanding = res.standing;
					}
				})
			).toPromise();
		}
	}

	public getGuarantorName() {
		const name = [this.account?.guarantor?.patient.first_name, this.account?.guarantor?.patient.last_name].filter(Boolean).join(' ');
		return `${name} (${this.account?.guarantor?.account_number})`
	}

	public get title() {
		return {
			create: 'Create Account',
			update: 'Update Account'
		}[this.action];
	}

	public get addressFormGroup(): FormGroup {
		return this.accountFormGroup.get('address') as FormGroup;
	}

	public disabled(determiningControl: string): boolean {
		return !this.accountFormGroup.get(determiningControl)?.value;
	}

	public async saveAccount(silent: boolean = false, onComplete?: () => Observable<any>): Promise<boolean> {
		this.accountFormGroup.updateValueAndValidity();
		if (this.accountFormGroup.invalid) {
			this.accountFormGroup.markAllAsTouched();
			return false;
		}

		this.loadingStateService.start('account-save');
		let accountData = { ...this.accountFormGroup.value, ...this.accountFormGroup.value.contact_details };
		let addressData = { ...this.accountFormGroup.value.address };

		delete accountData.contact_details;
		delete accountData.address;

		accountData.email = accountData.email || null;
		accountData.mobile_number = accountData.mobile_number || null;

		if (this.accountId) {
			return this.callHttpServices(
				forkJoin([
					this.accountsHttpService.update(this.accountId, accountData),
					this.addressId
						? this.accountsAddressesHttpService.update(this.accountId, this.addressId, addressData)
						: this.accountsAddressesHttpService.create(this.accountId, addressData)
				]).pipe(
					switchMap(v => {
						if (onComplete) {
							return onComplete().pipe(
								mapTo(v)
							);
						}
						return of(v);
					})
				),
				'Account updated successfully.',
				silent
			);
		}

		accountData.patient_id = this.patient?.user_id;
		return this.callHttpServices(
			this.accountsHttpService.create(accountData).pipe(
				mergeMap((account: AccountModel) => this.accountsAddressesHttpService.create(account.id, addressData))
			).pipe(
				switchMap(v => {
					if (onComplete) {
						return onComplete().pipe(
							mapTo(v)
						);
					}
					return of(v);
				})
			),
			'Account created successfully.',
			silent
		);
	}

	public patchForm(account: AccountModel, address?: BillingAddressModel) {
		this.accountFormGroup.patchValue({
			payment_method_id: account.payment_method_id,
			medical_aid_scheme_id: account.medicalAidOption?.medicalAidPlan?.scheme_id,
			medical_aid_plan_id: account.medicalAidOption?.plan_id,
			medical_aid_option_id: account.medical_aid_option_id,
			medikredit_option_id: account.medikredit_option_id,
			main_member_id: account.main_member_id,
			medical_aid_number: account.medical_aid_number,
			gap_cover: account.gap_cover,
			effective_date: account.effective_date ? new Date(account.effective_date as string) : null,
			dependant_code: account.dependant_code,
			contact_details: {
				mobile_number: account.mobile_number,
				email: account.email,
				telephone_number: account.telephone_number,
				fax_number: account.fax_number,
			},
			address: {
				type_id: ADDRESS_TYPES.HOME,
				country_id: address?.country_id,
				unit_number: address?.unit_number,
				complex: address?.complex,
				province: address?.province,
				city: address?.city,
				street: address?.street,
				municipality: address?.municipality,
				post_code: address?.post_code,
				display: address?.display,
				lat: address?.lat,
				lng: address?.lng,
			}
		});
	}

	public preloadPatientData(patient: PatientModel) {
		let tellPhone = null, cellPhone = null, address = null;
		if (patient.phones) {
			tellPhone = patient.phones.find(({ type }) => type === 'work') || null;
			cellPhone = patient.phones.find(({ type }) => type === 'contact') || null;
		}
		if (patient.addresses) {
			address = patient.addresses.find(({ type }) => type === 'home') || null;
		}
		this.accountFormGroup.patchValue({
			payment_method_id: patient.payment_method?.id,
			medical_aid_scheme_id: patient.medical_aid_details?.medical_aid_scheme_id,
			medical_aid_plan_id: patient.medical_aid_details?.medical_aid_plan_id,
			medical_aid_option_id: patient.medical_aid_details?.medical_aid_option_id,
			medikredit_option_id: null,
			main_member_id: patient.medical_aid_details?.main_member_id,
			medical_aid_number: patient.medical_aid_details?.medical_aid_number,
			gap_cover: false,
			effective_date: null,
			dependant_code: patient.medical_aid_details?.dependant_code,
			contact_details: {
				mobile_number: cellPhone?.phone,
				email: patient.email,
				telephone_number: tellPhone?.phone,
				fax_number: null
			},
			address: {
				type_id: ADDRESS_TYPES.HOME,
				country_id: address?.country_id,
				unit_number: address?.unit_number,
				complex: address?.complex,
				province: address?.province,
				city: address?.city,
				street: address?.street || address?.display,
				municipality: address?.municipality,
				post_code: address?.post_code,
				display: address?.display,
				lat: address?.location?.lat,
				lng: address?.location?.lng
			}
		});
	}

	public associateAccount() {
		let closure = () => {
			this.loadAccountData()
			modalRef.close();
		}
		const modalRef = this.modalService.open(AssociateAccountComponent, { size: 'lg', centered: true });
		modalRef.componentInstance.account = this.account;
		modalRef.componentInstance.closure = closure;
	}

	public removeAssociation() {
		this.confirmSubject.pipe(
			first(),
			tap(() => this.loadingStateService.start('account-save')),
			switchMap(() => {
				return this.accountsGuarantorHttpService.removeAssociation(this.accountId as number)
					.pipe(
						take(1),
						tap(() => this.successDialogService.showSuccessDialog('Guarantor Removed Successfully')),
						catchError(err => {
							this.errorDialogService.showErrorDialogFromResponse(err);
							return throwError(err);
						}),
						finalize(() => {
							this.loadingStateService.end('account-save');
							this.loadAccountData()
						})
					)
			})
		).subscribe();
		this.confirmDialogService.showConfirmDialog(
			'Are you sure you want to remove association ?',
			'WARNING',
			this.confirmSubject,
			undefined,
			'Confirm',
			'Cancel'
		);
	}

	private loadAccountData() {
		this.loadingStatus = 'Loading Account Data';
		forkJoin([
			this.accountsHttpService.find(this.accountId, {
				params: {
					include: 'medicalAidOption.medicalAidPlan,medicalAidStanding'
				}
			}),
			this.accountsAddressesHttpService.list(this.accountId!)
		]).pipe(
			finalize(() => this.loadingStatus = null),
			take(1)
		).subscribe({
			next: ([account, addresses]) => {
				const address = addresses.data.find(address => address.type_id === ADDRESS_TYPES.HOME)!;
				this.addressId = address?.id;
				this.account = account;
				this.patchForm(account, address);
			}
		});
	}

	private async callHttpServices(observable: Observable<any>, message: string, silent: boolean = false): Promise<boolean> {
		return observable.pipe(
			map(() => {
				if (!silent) {
					this.successDialogService.showSuccessDialog(message);
					this.router.navigate(...this.redirect);
				}
				return true;
			}),
			catchError(err => {
				this.errorDialogService.showErrorDialogFromResponse(err)
				return of(false);
			}),
			finalize(() => {
				this.loadingStateService.end('account-save');
			})
		).toPromise();
	}

	private initAccountForm(): void {
		this.accountFormGroup = this.fb.group({
			payment_method_id: [null, Validators.required],
			medical_aid_scheme_id: [null],
			medical_aid_plan_id: [null],
			medical_aid_option_id: [null],
			medikredit_option_id: [null],
			main_member_id: [null],
			medical_aid_number: [null],
			gap_cover: [null],
			effective_date: [null],
			dependant_code: [null],
			contact_details: this.fb.group({
				mobile_number: [null],
				email: [null, [Validators.email]],
				telephone_number: [null],
				fax_number: [null]
			}),
			address: this.fb.group({
				type_id: [ADDRESS_TYPES.HOME, Validators.required],
				country: [null],
				country_id: [null, Validators.required],
				unit_number: [null],
				complex: [null],
				province: [null, Validators.required],
				city: [null, Validators.required],
				street: [null, Validators.required],
				municipality: [null, Validators.required],
				post_code: [null, Validators.required],
				display: [null, Validators.required],
				lat: [null],
				lng: [null]
			})
		});
	}

	private initFormChangeSubscriptions(): void {
		this.accountFormGroup.get('payment_method_id')?.valueChanges.pipe(filter(v => !!v)).subscribe({
			next: res => {
				this.showMedicalAids = +res === PAYMENT_METHODS.MEDICAL_AID;
				if (this.showMedicalAids) {
					this.accountFormGroup.get('medical_aid_scheme_id')?.setValidators([Validators.required]);
					this.accountFormGroup.get('medical_aid_plan_id')?.setValidators([Validators.required]);
					this.accountFormGroup.get('medical_aid_option_id')?.setValidators([Validators.required]);
					this.accountFormGroup.get('main_member_id')?.setValidators([Validators.required]);
					this.accountFormGroup.get('medical_aid_number')?.setValidators([Validators.required]);
					// this.accountFormGroup.get('effective_date')?.setValidators([Validators.required]); // removed per EPN-2040
					this.accountFormGroup.get('dependant_code')?.setValidators([Validators.required]);
				} else {
					this.accountFormGroup.get('medical_aid_scheme_id')?.clearValidators();
					this.accountFormGroup.get('medical_aid_plan_id')?.clearValidators();
					this.accountFormGroup.get('medical_aid_option_id')?.clearValidators();
					this.accountFormGroup.get('main_member_id')?.clearValidators();
					this.accountFormGroup.get('medical_aid_number')?.clearValidators();
					// this.accountFormGroup.get('effective_date')?.clearValidators(); // removed per EPN-2040
					this.accountFormGroup.get('dependant_code')?.clearValidators();
					this.accountFormGroup.patchValue({
						main_member_id: null,
						medical_aid_number: null,
						effective_date: null,
						dependant_code: null
					});
				}
				this.accountFormGroup.patchValue({
					medical_aid_scheme_id: null,
					medical_aid_plan_id: null,
					medical_aid_option_id: null
				});
				this.accountFormGroup.updateValueAndValidity({ emitEvent: false });
			}
		});

		this.accountFormGroup.get('medical_aid_scheme_id')?.valueChanges.pipe(filter(v => !!v)).subscribe({
			next: res => {
				this.accountFormGroup.patchValue({
					medical_aid_plan_id: '',
					medical_aid_option_id: ''
				});
				this.medicalAidPlansService.list({
					params: { 'filter[scheme_id]': res }
				}).toPromise()
					.then(res => this.medicalAidPlans = res);
			}
		});

		this.accountFormGroup.get('medical_aid_plan_id')?.valueChanges.pipe(filter(v => !!v)).subscribe({
			next: res => {
				this.accountFormGroup.patchValue({
					medical_aid_option_id: ''
				});
				this.medicalAidOptionsService.list({
					params: { 'filter[plan_id]': res, 'include': 'medikredit_options' }
				}).toPromise()
					.then(res => {
						this.medicalAidOptions = res;
						this.resolveMedikreditValue(this.accountFormGroup.get('medical_aid_option_id')?.value);
					})
			}
		});

		this.accountFormGroup.get('medical_aid_option_id')?.valueChanges.subscribe({
			next: res => {
				this.resolveMedikreditValue(res);
			}
		});
	}

	private resolveMedikreditValue(optionId?: number) {
		if (optionId) {
			const option = this.medicalAidOptions.find(v => v.id === optionId);
			if (option && option.medikredit_options && option.medikredit_options.length) {

				this.medikreditOptionMatches = option.medikredit_options;
				const match = option.medikredit_options.find(v => !v.is_dispensing);
				if (match) {
					const found = this.medikreditOptions.find(v => v.id === match.id);
					if (found) {
						this.accountFormGroup.get('medikredit_option_id')?.patchValue(found.id);
					}
				}
			}
		} else {
			this.medikreditOptionMatches = [];
		}
	}

	private async initFromParams(params: { patientId: number }) {
		if (params.patientId) {
			const patient = await this.patientHttpService.find(params.patientId, {
				params: { include: 'payment_method,medical_aid_details,phones,addresses' }
			}).toPromise();
			if (patient) {
				const result = {
					isActive: true,
					label: [patient.first_name, patient.last_name].filter(Boolean).join(' '),
					meta: [patient.email, patient.phone].filter(Boolean).join(', '),
					...patient
				};
				this.defaultItems.next([result]);
				this.patient = result;
				this.preloadPatientData(result);
			}
		}
	}
}
