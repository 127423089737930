<div class="container-fluid" [ngStyle]="{ 'display': loadingStatus === null ? 'block' : 'none' }"
     [formGroup]="invoiceForm">
    <ng-container *ngIf="invoiceLoaded">
        <div class="row">
            <div class="col">
                <h1 class="card-title text-epione text-uppercase font-weight-bold">
                    {{ invoiceData ? 'Update' : 'Capture' }} Invoice
                    <span *ngIf="invoiceData">:&nbsp;{{ invoiceData.invoice_number }}</span>
                </h1>
            </div>
            <div class="col-auto" *ngIf="invoiceData">
                <div class="text-muted text-right">Created: {{ invoiceData.created_at | date: 'medium' }}</div>
                <div class="text-muted text-right">Last Updated: {{ invoiceData.updated_at | date: 'medium' }}</div>
            </div>

        </div>
        <div class="line-top row justify-content-end mb-3">
            <!-- TABS here -->
            <div class="tab col-1 border border-primary rounded-top text-center font-weight-bold py-2 px-3"
                 [ngClass]=" tab === 'Invoice' ? 'bg-epione text-white': 'text-primary' " (click)="setTab('Invoice')">
                Invoice</div>
            <div class="tab col-1 border border-primary rounded-top text-center font-weight-bold py-2 px-3"
                 [ngClass]=" tab === 'Payments' ? 'bg-epione text-white' : 'text-primary' "
                 (click)="setTab('Payments')">Payments</div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="media" *ngIf="practitioner;else selectDoctor">
                    <div class="media-image align-self-start mr-3 d-flex flex-column">
                        <img [src]="practitioner?.photo ? practitioner?.photo?.link : './assets/images/blank-avatar.png'"
                             onerror="this.src='./assets/images/blank-avatar.png';"
                             class="profile-image align-self-start mr-3">
                        <button epioneLoadingState="invoice-save" *ngIf="canEditDoctor && action === 'create'"
                                (click)="practitioner = null; account = null; lifecycle = null"
                                class="btn btn-primary btn-sm">
                            <mat-icon [inline]="true">edit</mat-icon>&nbsp;Change
                        </button>
                    </div>
                    <div class="media-body">
                        <p><strong>{{ practitioner?.name }}</strong></p>
                        <p>{{ practitioner?.email }}</p>
                        <p>{{ practitioner?.phone }}</p>
                    </div>
                </div>
                <ng-template #selectDoctor>
                    <epione-select-practitioner
                                                (loaded)="loadComplete('practitioner')"
                                                [practitionerId]="practitionerId"
                                                [practitionerRoleId]="practitionerRoleId"
                                                [(ngModel)]="practitioner"
                                                [ngModelOptions]="{standalone: true}">
                    </epione-select-practitioner>
                </ng-template>
            </div>
            <div class="col-md-4">
                <div class="media"
                     *ngIf="account && (practitioner || (action == 'update' && !practitionerId));else selectAccount">
                    <div class="media-image align-self-start mr-3 d-flex flex-column">
                        <img [src]="account.patient.photo && account.patient.photo.link ? account.patient.photo.link : './assets/images/blank-avatar.png'"
                             onerror="this.src='./assets/images/blank-avatar.png';"
                             class="profile-image align-self-start mr-3">
                        <button epioneLoadingState="invoice-save" *ngIf="action === 'create'"
                                (click)="account = null; lifecycle = null"
                                class="btn btn-primary btn-sm">
                            <mat-icon [inline]="true">edit</mat-icon>&nbsp;Change
                        </button>
                    </div>
                    <div class="media-body">
                        <p><strong>{{ account.patient.first_name }} {{ account.patient.last_name }}</strong></p>
                        <p>{{ account.email }}</p>
                        <p>{{ account.mobile_number }}</p>
                        <p>{{ account.patient.birthday ? 'DOB: '+account.patient.birthday : '' }}</p>
                        <div *ngIf="account.payment_method_id === 2">
                            <strong>MEDICAL AID</strong>
                            <div
                                 class="ml-1"
                                 [title]="account.medicalAidOption?.medicalAidPlan?.medicalAidScheme?.name + ' / ' + account.medicalAidOption?.medicalAidPlan?.name + ' / ' + account.medicalAidOption?.name">
                                Scheme: {{ account.medicalAidOption?.medicalAidPlan?.medicalAidScheme?.name }}
                            </div>
                            <div class="ml-1">Membership Number: {{ account.medical_aid_number }}</div>
                            <div class="ml-1">Dependant Code: {{ account.dependant_code }}</div>
                            <div class="ml-1">Main Member ID: {{ account.main_member_id }}</div>
                        </div>
                        <div *ngIf="dpcPatientFlag">
                            <strong>DPC PILOT STATUS</strong>
                            <div class="ml-2" *ngIf="dpcPatientFlag.data.in_arrears === false">
                                <strong style="color: #72bb53">Active</strong>
                            </div>
                            <div class="ml-2" *ngIf="dpcPatientFlag.data.in_arrears === true">
                                <ng-container
                                              *ngIf="!!dpcPatientFlag.data.latest_invoice_id && dpcPatientFlag.data.latest_invoice_id !== invoiceData?.id; else noLatestId">
                                    <a class="dpcLink"
                                       [routerLink]="['/invoice/update', dpcPatientFlag.data.latest_invoice_id]"
                                       title="Click to view latest outstanding invoice">
                                        <strong style="color: #b70f0a">Active In Arrears</strong>
                                    </a>
                                </ng-container>
                                <ng-template #noLatestId>
                                    <strong style="color: #b70f0a">Active In Arrears</strong>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-template #selectAccount>
                    <ng-container
                                  *ngIf="practitioner || (action == 'update' && !practitionerId && accountId); else noDoctor">
                        <epione-select-account
                                               (loaded)="loadComplete('account')"
                                               [accountId]="accountId"
                                               [(ngModel)]="account"
                                               [practitionerId]="practitioner?.id"
                                               [ngModelOptions]="{standalone: true}">
                        </epione-select-account>
                    </ng-container>
                    <ng-template #noDoctor>
                        <div class="form-group">
                            <label class="text-epione">
                                Please first select a practitioner to list the accounts for their respective patients
                            </label>
                        </div>
                    </ng-template>
                </ng-template>
            </div>
            <div class="col-md-4">
                <ng-container
                              *ngIf="(practitioner && account) || (action == 'update' && !practitionerId && account); else noAccount">
                    <epione-select-case
                                        epioneLoadingState="invoice-save"
                                        (loaded)="loadComplete('case')"
                                        [caseId]="caseId"
                                        [lifecycleId]="lifecycleId"
                                        [(ngModel)]="lifecycle"
                                        [disabled]="!!invoiceForm.get('case_lifecycle_id')?.disabled"
                                        [patientId]="account.patient_id"
                                        [practitionerId]="practitioner?.id"
                                        [practitionerRoleId]="practitioner?.role_id"
                                        [ngModelOptions]="{standalone: true}">
                    </epione-select-case>
                    <label *ngIf="!lifecycle" class="text-epione">
                        Alternatively specify an invoice date if billing for non case related items.
                    </label>
                    <div class="row">
                        <div class="col-6 form-group">
                            <label for="invoice-date" class="form-label">Invoice Date</label>
                            <div class="input-group">
                                <input type="text" class="form-control"
                                       epioneLoadingState="invoice-save"
                                       ngbDatepicker
                                       #invoiceDatePicker="ngbDatepicker"
                                       id="invoice-date"
                                       formControlName="invoice_date">
                                <div class="input-group-prepend" (click)="invoiceDatePicker.toggle()">
                                    <div class="input-group-text">
                                        <span class="material-icons">calendar_today</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 form-group">
                            <label for="invoice-date" class="form-label">Due Date</label>
                            <div class="input-group">
                                <input type="text" class="form-control"
                                       epioneLoadingState="invoice-save"
                                       ngbDatepicker
                                       #dueDatePicker="ngbDatepicker"
                                       id="invoice-date"
                                       formControlName="due_date">
                                <div class="input-group-prepend" (click)="dueDatePicker.toggle()">
                                    <div class="input-group-text">
                                        <span class="material-icons">calendar_today</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-template #noAccount>
                    <div class="form-group">
                        <label class="text-epione">
                            Please first select a practitioner & account to invoice
                        </label>
                    </div>
                </ng-template>
            </div>
        </div>
        <ng-container *ngIf="tab === 'Payments'">
            <div class="row">
                <div class="col-12">
                    <div class="table-responsive">
                        <table class="invoice-table table table-borderless mt-5">
                            <thead>
                                <tr class="text-epione text-uppercase">
                                    <th scope="col">PAYMENT DATE</th>
                                    <ng-container *ngIf="useMediKredit">
                                        <th scope="col">CLAIM NUMBER</th>
                                        <th scope="col">CLAIMED</th>
                                        <th scope="col">PAID BY SCHEME</th>
                                        <th scope="col">PAID TO PATIENT</th>
                                        <th scope="col">PATIENT LIABLE</th>
                                    </ng-container>
                                    <th scope="col">PAID BY PATIENT</th>
                                    <ng-container *ngIf="useMediKredit">
                                        <th scope="col">REASON CODE</th>
                                    </ng-container>
                                    <th scope="col">DESCRIPTION</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of payments">
                                    <td>{{ row.date | date: 'yyyy-MM-dd' }}</td>
                                    <ng-container *ngIf="useMediKredit">
                                        <td [ngClass]="{ 'text-muted': !row.claim_number }">
                                            {{ row.claim_number || 'N/A' }}
                                        </td>
                                        <td [ngClass]="{ 'text-muted': !row.claim_amount }">
                                            <ng-container *ngIf="!!row.claim_amount">
                                                {{ row.claim_amount | currency: currency}}
                                            </ng-container>
                                            <ng-container *ngIf="!row.claim_amount">-</ng-container>
                                        </td>
                                        <td [ngClass]="{ 'text-muted': !row.scheme_amount_paid }">
                                            <ng-container *ngIf="!!row.scheme_amount_paid">
                                                {{ row.scheme_amount_paid | currency: currency}}
                                            </ng-container>
                                            <ng-container *ngIf="!row.scheme_amount_paid">-</ng-container>
                                        </td>
                                        <td [ngClass]="{ 'text-muted': !row.paid_to_patient }">
                                            <ng-container *ngIf="!!row.paid_to_patient">
                                                {{ row.paid_to_patient | currency: currency}}
                                            </ng-container>
                                            <ng-container *ngIf="!row.paid_to_patient">-</ng-container>
                                        </td>
                                        <td [ngClass]="{ 'text-muted': !row.patient_liable }">
                                            <ng-container *ngIf="!!row.patient_liable">
                                                {{ row.patient_liable | currency: currency}}
                                            </ng-container>
                                            <ng-container *ngIf="!row.patient_liable">-</ng-container>
                                        </td>
                                    </ng-container>
                                    <td>{{ (row.paid_by_patient || 0) | currency: currency }}</td>
                                    <ng-container *ngIf="useMediKredit">
                                        <td [ngClass]="{ 'text-muted': !row.reason_code }">
                                            {{ row.reason_code || 'N/A' }}
                                        </td>
                                    </ng-container>
                                    <td>
                                        {{ row.type.name }}: {{ row.note }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="tab === 'Invoice'">
            <div class="row">
                <div class="col-12 invoice-table mt-5">
                    <div class="row text-epione text-uppercase text-bold">
                        <div class="col-1" scope="col"></div>
                        <div class="col-2" scope="col">Service Date</div>
                        <div class="col-6" scope="col">Summary</div>
                        <div class="col-3" scope="col">Line Total</div>
                    </div>
                    <div class="row">
                        <div class="col-12 line-items" formArrayName="line_items" cdkDropList
                             (cdkDropListDropped)="drop($event)">
                            <ng-container *ngFor="let control of lineItemsFormArray.controls; let i = index;"
                                          [formGroupName]="i">
                                <ng-container *ngIf="lineItemIsVisible(control)">
                                    <div class="row line-item-row" cdkDrag
                                         [ngClass]="{'expanded': expandedRows.includes(i), 'invalid': (control.invalid && control.touched) || hasClaimItems(control, 2), 'warning': hasClaimItems(control, 1) }">
                                        <div class="col-1">
                                            <div class="row">
                                                <div class="col-auto drag-handle">
                                                    <mat-icon cdkDragHandle>drag_indicator</mat-icon>
                                                </div>
                                                <div class="col-auto">
                                                    <button class="btn btn-primary btn-expandRow"
                                                            epioneLoadingState="invoice-save"
                                                            (click)="toggleExpandedRow(i)">
                                                        Edit&nbsp;
                                                        <mat-icon [inline]="true" *ngIf="expandedRows.includes(i)">
                                                            arrow_drop_down
                                                        </mat-icon>
                                                        <mat-icon [inline]="true" *ngIf="!expandedRows.includes(i)">
                                                            arrow_right
                                                        </mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2">
                                            <div class="input-group">
                                                <input type="text" class="form-control"
                                                       epioneLoadingState="invoice-save"
                                                       ngbDatepicker
                                                       container="body"
                                                       #serviceDatePicker="ngbDatepicker"
                                                       formControlName="service_date">
                                                <div class="input-group-prepend"
                                                     (click)="serviceDatePicker.toggle()">
                                                    <div class="input-group-text">
                                                        <span
                                                              class="material-icons">calendar_today</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="input-group">
                                                <input type="text" disabled class="form-control"
                                                       epioneLoadingState="invoice-save"
                                                       [value]="getSummaryText(control)" />
                                                <div class="input-group-append">
                                                    <button class="btn btn-info py-0 px-2"
                                                            (click)="editDescription(control)">
                                                        <mat-icon [inline]="true"
                                                                  style="font-size: 1.45em">
                                                            edit_note
                                                        </mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2">
                                            <epione-currency-input
                                                                   epioneFormRequired
                                                                   [disabled]="true"
                                                                   [currency]="currency"
                                                                   [ngModel]="getLineAmount(control)"
                                                                   [ngModelOptions]="{standalone: true}">
                                            </epione-currency-input>
                                        </div>
                                        <div class="col-1">
                                            <button class="btn btn-danger" (click)="removeLineItem(i)"
                                                    [disabled]="payments && payments.length"
                                                    [title]="payments && payments.length ? 'Cannot Remove An Invoice Line Item With Payments Logged' : 'Remove Line Item'"
                                                    epioneLoadingState="invoice-save"
                                                    *epioneInvoiceIncomplete="[invoiceData?.invoice_status_id, true]">Remove</button>
                                        </div>
                                    </div>
                                    <div class="row expandedRow" *ngIf="expandedRows.includes(i)">
                                        <div class="col-12">
                                            <div class="row expandedRowInner py-3">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col">Tariff</div>
                                                        <div class="col">NAPPI</div>
                                                        <div class="col">ICD10</div>
                                                        <div class="col-1">Quantity</div>
                                                        <div class="col-2">Unit Amount</div>
                                                        <div class="col-1">Discount Rate</div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col">
                                                            <input *ngIf="control.get('tariff_code_txt')?.value;else tariffTmpl"
                                                                   type="text"
                                                                   class="form-control"
                                                                   epioneLoadingState="invoice-save"
                                                                   placeholder="Procedure Code"
                                                                   id="tariff-code"
                                                                   epioneFormRequired
                                                                   formControlName="tariff_code_txt">
                                                            <ng-template #tariffTmpl>
                                                                <epione-select-tariff epioneLoadingState="invoice-save"
                                                                                      formControlName="tariff_code_json">
                                                                </epione-select-tariff>
                                                            </ng-template>
                                                        </div>
                                                        <div class="col">
                                                            <epione-select-nappi epioneLoadingState="invoice-save"
                                                                                 formControlName="nappi_code_id">
                                                            </epione-select-nappi>
                                                        </div>
                                                        <div class="col">
                                                            <epione-select-icd10 epioneLoadingState="invoice-save"
                                                                                 formControlName="icd10_codes">
                                                            </epione-select-icd10>
                                                        </div>
                                                        <div class="col-1">
                                                            <input type="number" class="form-control"
                                                                   epioneLoadingState="invoice-save"
                                                                   placeholder="Quantity"
                                                                   min="0"
                                                                   id="quantity"
                                                                   epioneFormRequired
                                                                   formControlName="quantity">
                                                        </div>
                                                        <div class="col-2">
                                                            <epione-currency-input
                                                                                   epioneFormRequired
                                                                                   [currency]="currency"
                                                                                   formControlName="unit_amount">
                                                            </epione-currency-input>
                                                        </div>
                                                        <div class="col-1">
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <div class="input-group-text">%</div>
                                                                </div>
                                                                <input type="number"
                                                                       class="form-control"
                                                                       epioneLoadingState="invoice-save"
                                                                       min="1"
                                                                       max="100"
                                                                       formControlName="discount_rate">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row"
                                                         *ngIf="hasClaimItems(control)">
                                                        <div class="col">
                                                            <div class="claim-code-item">
                                                                <p *ngFor="let code of claimMessagesBySection(control.value.id, 'tariff')"
                                                                   class="claim-code"
                                                                   [ngClass]="'severity-'+code.severity">
                                                                    <strong *ngIf="code.code">{{code.code}}</strong>
                                                                    {{code.message}}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <div class="claim-code-item">
                                                                <p *ngFor="let code of claimMessagesBySection(control.value.id, 'nappi')"
                                                                   class="claim-code"
                                                                   [ngClass]="'severity-'+code.severity">
                                                                    <strong *ngIf="code.code">{{code.code}}</strong>
                                                                    {{code.message}}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <div class="claim-code-item">
                                                                <p *ngFor="let code of claimMessagesBySection(control.value.id, 'icd10')"
                                                                   class="claim-code"
                                                                   [ngClass]="'severity-'+code.severity">
                                                                    <strong *ngIf="code.code">{{code.code}}</strong>
                                                                    {{code.message}}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="col-3 offset-1">
                                                            <div class="claim-code-item">
                                                                <p *ngFor="let code of claimMessagesBySection(control.value.id, 'price')"
                                                                   class="claim-code"
                                                                   [ngClass]="'severity-'+code.severity">
                                                                    <strong *ngIf="code.code">{{code.code}}</strong>
                                                                    {{code.message}}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 text-center" *ngIf="!lineItemsFormArray.controls.length">
                            No Line Items To Display
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col">
                            <div class="claim-code-item" *ngFor="let item of claimItems(null)">
                                <p *ngFor="let code of item.codes" class="claim-code"
                                   [ngClass]="'severity-'+code.severity">
                                    <strong>{{code.code}}</strong> {{code.description}}
                                </p>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="row" *ngIf="totalTax">
                                <div class="col-6">
                                    <div class="w-100 h-100 d-flex align-items-center justify-content-end">
                                        <label class="text-epione text-uppercase m-0">
                                            Tax:
                                        </label>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <epione-currency-input
                                                           epioneFormRequired
                                                           [disabled]="true"
                                                           [currency]="currency"
                                                           [ngModel]="totalTax"
                                                           [ngModelOptions]="{standalone: true}">
                                    </epione-currency-input>
                                </div>
                                <div class="col-2"></div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <div class="w-100 h-100 d-flex align-items-center justify-content-end">
                                        <label class="text-epione text-uppercase m-0">
                                            Total:
                                        </label>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">%</div>
                                        </div>
                                        <input type="number"
                                               class="form-control"
                                               epioneLoadingState="invoice-save"
                                               min="1"
                                               max="100"
                                               [ngModelOptions]="{standalone: true}"
                                               [(ngModel)]="totalDiscount"
                                               (ngModelChange)="applyTotalDiscount($event)">
                                    </div>
                                </div>
                                <div class="col-4">
                                    <epione-currency-input
                                                           epioneFormRequired
                                                           [disabled]="true"
                                                           [currency]="currency"
                                                           [ngModel]="total"
                                                           [ngModelOptions]="{standalone: true}">
                                    </epione-currency-input>
                                </div>
                                <div class="col-2"></div>
                            </div>
                            <div class="row mt-3 pt-3 border-top"
                                 *ngIf="activeClaimTransaction && activeClaimTransaction?.approved_gross">
                                <div class="col-6">
                                    <div class="w-100 h-100 d-flex align-items-center justify-content-end">
                                        <label class="text-epione text-uppercase m-0">
                                            Approved Claim Gross:
                                        </label>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <epione-currency-input
                                                           epioneFormRequired
                                                           [disabled]="true"
                                                           [currency]="currency"
                                                           [ngModel]="activeClaimTransaction?.approved_gross"
                                                           [ngModelOptions]="{standalone: true}">
                                    </epione-currency-input>
                                </div>
                            </div>
                            <div class="row mt-1"
                                 *ngIf="activeClaimTransaction && activeClaimTransaction?.approved_discount">
                                <div class="col-6">
                                    <div class="w-100 h-100 d-flex align-items-center justify-content-end">
                                        <label class="text-epione text-uppercase m-0">
                                            Approved Claim Discount:
                                        </label>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <epione-currency-input
                                                           epioneFormRequired
                                                           [disabled]="true"
                                                           [currency]="currency"
                                                           [ngModel]="activeClaimTransaction?.approved_discount"
                                                           [ngModelOptions]="{standalone: true}">
                                    </epione-currency-input>
                                </div>
                            </div>
                            <div class="row mt-1"
                                 *ngIf="activeClaimTransaction && activeClaimTransaction?.approved_levy">
                                <div class="col-6">
                                    <div class="w-100 h-100 d-flex align-items-center justify-content-end">
                                        <label class="text-epione text-uppercase m-0">
                                            Patient Levy:
                                        </label>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <epione-currency-input
                                                           epioneFormRequired
                                                           [disabled]="true"
                                                           [currency]="currency"
                                                           [ngModel]="activeClaimTransaction?.approved_levy"
                                                           [ngModelOptions]="{standalone: true}">
                                    </epione-currency-input>
                                </div>
                            </div>
                            <div class="row mt-1"
                                 *ngIf="activeClaimTransaction && activeClaimTransaction?.approved_nett">
                                <div class="col-6">
                                    <div class="w-100 h-100 d-flex align-items-center justify-content-end">
                                        <label class="text-epione text-uppercase m-0">
                                            Approved Claim Nett / Payable To Provider:
                                        </label>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <epione-currency-input
                                                           epioneFormRequired
                                                           [disabled]="true"
                                                           [currency]="currency"
                                                           [ngModel]="activeClaimTransaction?.approved_nett"
                                                           [ngModelOptions]="{standalone: true}">
                                    </epione-currency-input>
                                </div>
                            </div>
                            <div class="row mt-1"
                                 *ngIf="activeClaimTransaction && activeClaimTransaction?.approved_gross">
                                <div class="col-6">
                                    <div class="w-100 h-100 d-flex align-items-center justify-content-end">
                                        <label class="text-epione text-uppercase m-0">
                                            Patient Liable / Payable To Provider:
                                        </label>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <epione-currency-input
                                                           epioneFormRequired
                                                           [disabled]="true"
                                                           [currency]="currency"
                                                           [ngModel]="patientLiable"
                                                           [ngModelOptions]="{standalone: true}">
                                    </epione-currency-input>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ng-container *epioneInvoiceComplete="invoiceData?.invoice_status_id">
                        <div class="row">
                            <div class="col"></div>
                            <div class="col-auto text-epione text-uppercase text-right">
                                Amount Paid:
                            </div>
                            <div class="col-2">
                                <strong>{{ invoiceData?.amount_paid | currency: currency }}</strong>
                            </div>
                            <div class="col-1"></div>
                        </div>
                        <div class="row">
                            <div class="col"></div>
                            <div class="col-auto text-epione text-uppercase text-right">
                                Amount Due:
                            </div>
                            <div class="col-2">
                                <strong>{{ invoiceData?.amount_due | currency: currency }}</strong>
                            </div>
                            <div class="col-1"></div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="mt-3 d-flex justify-content-center"
                 *epioneInvoiceIncomplete="[invoiceData?.invoice_status_id, true]">
                <button class="btn btn-primary bg-epione mr-3" [disabled]="payments && payments.length"
                        [title]="payments && payments.length ? 'Cannot Add To An Invoice With Payments Logged' : 'Add Line Item'"
                        (click)="addLineItem()"
                        epioneLoadingState="invoice-save">
                    + Add Line Item
                </button>
                <button class="btn btn-primary bg-epione" [disabled]="payments && payments.length"
                        [title]="payments && payments.length ? 'Cannot Add To An Invoice With Payments Logged' : 'Add Line Items From Basket'"
                        (click)="addBasket()"
                        epioneLoadingState="invoice-save">
                    + Add Basket
                </button>
            </div>
            <div class="epione-line mt-3 mb-3"></div>
            <div class="row">
                <div class="col">
                    <div class="d-flex justify-content-start">
                        <ng-container *epioneInvoiceAuthorized="invoiceData?.invoice_status_id">
                            <button class="btn btn-primary mx-3"
                                    *ngIf="invoiceData && invoiceData.amount_due > 0"
                                    (click)="trackPayment()"
                                    epioneLoadingState="invoice-save">
                                Allocate Payment
                            </button>
                            <button class="btn btn-primary mx-3" *ngIf="invoiceData"
                                    (click)="sendInvoice()"
                                    epioneLoadingState="invoice-save">
                                {{ invoiceData?.sent ? 'Re-Send' : 'Send' }} Invoice
                            </button>
                        </ng-container>
                    </div>
                </div>
                <div class="col">
                    <div class="d-flex justify-content-end">
                        <ng-container *epioneInvoiceIncomplete="[invoiceData?.invoice_status_id, true]">
                            <button class="btn btn-primary mx-3" (click)="submitInvoice(false)"
                                    epioneLoadingState="invoice-save">
                                {{ action === 'create' ? 'Create' : 'Save' }} Invoice
                            </button>
                            <ng-container *epioneInvoiceDraft="[invoiceData?.invoice_status_id, true]">
                                <button *ngIf="action !== 'create'" class="btn btn-primary mx-3"
                                        (click)="submitInvoice(true)"
                                        epioneLoadingState="invoice-save">
                                    Save & Approve Invoice
                                </button>
                            </ng-container>
                            <button class="btn btn-primary mx-3" *ngIf="invoiceData?.id && useMediKredit"
                                    (click)="claimInvoice()"
                                    epioneLoadingState="invoice-save">
                                Save & {{ activeClaimTransaction ? 'Re-Submit' : 'Submit' }} To Medical Aid
                            </button>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>

<div [ngStyle]="{ 'display': loadingStatus === null ? 'none' : 'block' }">
    <div class="">
        <loader [message]="loadingStatus"></loader>
    </div>
</div>

<ng-template #claimModal>
    <div class="modal-header">
        <h4 class="modal-title mb-0 text-primary">Claim Submission</h4>
    </div>
    <div class="modal-body text-center">
        <p>
            <strong>NOTICE:</strong> {{ claimResult?.message }}
        </p>
        <button type="button" class="btn btn-outline-primary mx-2 w-25" (click)="claimModalRef?.close()"
                epioneLoadingState="invoice-authorize">Close</button>
    </div>
</ng-template>

<ng-template #descriptionModal>
    <div class="modal-header">
        <h4 class="modal-title mb-0 text-primary">Description</h4>
    </div>
    <div class="modal-body text-center">
        <textarea class="form-control" [formControl]="activeDescription" rows="5"
                  *ngIf="activeDescriptionControl"></textarea>
    </div>
    <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-outline-warning" (click)="overwriteDescription()"
                epioneLoadingState="invoice-authorize">Re-Generate Description</button>
        <button type="button" class="btn btn-outline-primary" (click)="descriptionModalRef?.close()"
                epioneLoadingState="invoice-authorize">Close</button>
    </div>
</ng-template>