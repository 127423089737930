import { Injectable } from '@angular/core';
import { BillingHttpApiService } from '@epione/shared/services/http/billing-http-api.service';
import { MedpraxTariffCodeQueryItem } from '@epione/shared/types/medprax';

@Injectable({
  providedIn: 'root'
})
export class MedpraxTariffHttpService extends BillingHttpApiService<MedpraxTariffCodeQueryItem> {
  protected resourcePath = "coding/medprax/tariff";
}
