import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Injectable()
export class EpioneDateFormatter extends NgbDateParserFormatter {

	private FORMAT: string = 'YYYY-MM-DD';

	parse(value: string): NgbDateStruct | null {
		if (value && value !== '') {
			let date = moment(value);
			return {
				day: date.date(),
				month: date.month() + 1, // moment 0 indexes, this plugin 1 indexes
				year: date.year()
			};
		}
		return null;
	}

	format(date: NgbDateStruct | null): string {
		if (date) {
			return moment({ ...date, month: date.month - 1 }).format(this.FORMAT)
		}
		return '';
	}
}