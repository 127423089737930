import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from '@epione/shared/config/common.config';
import { UserModel } from '@epione/shared/models/user.model';
import { USER_ROLE_ID } from '@epione/shared/config/roles.config';
import { JwtTokenModel } from '@epione/shared/models/jwt-token.model';
import * as moment from 'moment';
import { FinAccountMappingService } from '@epione/modules/settings/fin-account-mapping/services/fin-account-mapping.service';
import { PracticeModel } from '@epione/shared/models/practice.model';
import { IntegrationModel } from '@epione/shared/models/Integration.model';
import { CurrencyModel } from '@epione/shared/models/currency.model';


export class InvalidIntegrationSetupError extends Error { }

interface ActiveUserModel extends UserModel {
    profile: any; // can be any type of user, needs to be typed on call
    practice: PracticeModel; // it WILL be there on token/auth calls
    integration: IntegrationModel; // it WILL be there on token/auth calls
}

@Injectable({
    providedIn: 'root'
})
export class ActiveUserService {

    private initialisationError: Error | null = null;
    private userData?: ActiveUserModel;
    private tokenData?: JwtTokenModel;
    private allowedRoles: number[] = [
        USER_ROLE_ID.GENERAL_PRACTITIONER,
        USER_ROLE_ID.SPECIALIST,
        USER_ROLE_ID.PRACTICE_MANAGER,
        USER_ROLE_ID.PRIMARY_CARE_NURSE
    ];
    private initialised: boolean = false;
    private initialisedListeners: Array<(err: Error | null) => void> = [];
    private refreshTimeout: any;
    private accountsValid: boolean = false;

    constructor(
        private http: HttpClient,
        private accountMappingService: FinAccountMappingService
    ) { }

    public get id(): number | null {
        return this.userData?.id || null;
    }

    public get user(): ActiveUserModel | null {
        return this.userData || null;
    }

    public get practiceId(): number | null {
        return this.userData?.practice.id || null;
    }

    public get practice(): PracticeModel {
        return this.userData?.practice as PracticeModel;
    }

    public set practice(p: PracticeModel) {
        if (this.userData) {
            this.userData.practice = p;
        }
    }

    public get integrationId(): number | null {
        return this.userData?.integration.id || null;
    }

    public get integration(): IntegrationModel | null {
        return this.userData?.integration || null;
    }

    public get currency(): CurrencyModel | null {
        return this.integration?.details?.currency as CurrencyModel || null;
    }

    public get token(): string | null {
        return this.tokenData?.token || null;
    }

    public get claims(): { [key: string]: string | number } | null {
        return this.tokenData?.claims || null;
    }

    public get validAccountSetup(): boolean {
        return this.accountsValid; // add on anything else here as: `check1 && check2 && check3`
    }

    public loadActiveUser(force: boolean = false) {
        if (this.initialised && !force) {
            return;
        }
        const params = new URLSearchParams(window.location.href.split('?')[1]);
        return this.refreshSession(params.get('token'))
            .then(() => this.validateIntegration())
            .then(() => {
                this.initialised = true;
                this.initialisedListeners.forEach(cb => cb(null))
            })
            .catch(err => {
                this.initialised = false;
                this.initialisedListeners.forEach(cb => cb(err))
            });
    }

    public async isAuthed(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.onInitialised(() => {
                return resolve(!!this.user);
            });
        });
    }

    public setToken(token: string) {
        this.tokenData = this.parseToken(token);
        this.queueRefresh();
    }

    public onInitialised(cb: (error: Error | null) => void) {
        if (this.initialised || this.initialisationError) {
            cb(this.initialisationError);
            return;
        }
        this.initialisedListeners.push(cb);
    }

    public logout() {
        this.clearSession();
        window.location.replace(APP_CONFIG.APP_URL.main);
    }

    public refreshSession(token: string | null = null): Promise<boolean> {
        return this.http.post(`${APP_CONFIG.API_URL.billing}/auth/user`, {
            token: token ? token : this.token
        }).toPromise()
            .then(async (res) => {
                let userModel: ActiveUserModel = res as ActiveUserModel;

                if (!this.allowedRoles.includes(userModel.role?.id as number)) {
                    // invalid role, fail
                    throw new Error('Invalid User Role');
                }

                this.userData = userModel;
                this.setToken(userModel.token as string);
                return true;
            })
            .catch((err) => {
                this.logout();
                return false;
            });
    }

    public validateIntegration() {
        return this.accountMappingService.checkAccountMappings()
            .toPromise()
            .then(res => {
                if (res) {
                    this.accountsValid = true;
                    return true;
                }
                throw new InvalidIntegrationSetupError();
            })
            .catch(() => {
                this.initialisationError = new InvalidIntegrationSetupError();
                throw this.initialisationError
            })
    }

    private clearSession() {
        this.userData = undefined;
        this.tokenData = undefined;
    }

    private parseToken(token: string): JwtTokenModel {
        return {
            token,
            claims: JSON.parse(atob(token.split('.')[1])) as { [key: string]: string | number }
        };
    }

    private queueRefresh() {
        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
        }
        if (this.claims) {
            const claims: { exp?: number, nbf?: number } = this.claims;
            const range = Math.min((claims.exp as number - (claims.nbf as number)) * .75, 3 * 60); // min 3min or range
            const timeout = moment.unix(claims.exp as number).subtract(range, 'seconds').diff(moment(), 'milliseconds');
            this.refreshTimeout = setTimeout(() => {
                this.refreshSession();
            }, timeout);
        }
    }
}

export function ActiveUserServiceFactory(provider: ActiveUserService) {
    return () => provider.loadActiveUser();
}
