
import { Injectable } from '@angular/core';
import { ClaimTransactionModel } from '@epione/shared/models/medikredit/claim-transaction.model';
import { PaginatedResponse } from '@epione/shared/types/paginatedResponse';
import { BillingNestedHttpApiService } from '@epione/shared/services/http/billing-nested-http-api.service';

@Injectable({
  providedIn: 'root'
})

export class ClaimTransactionHttpService extends BillingNestedHttpApiService<ClaimTransactionModel, PaginatedResponse<ClaimTransactionModel>> {
  protected resourcePath = 'invoices/{:parentId}/medikredit/transactions/list';
}