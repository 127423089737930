import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyModel } from '../models/currency.model';
import Decimal from 'decimal.js';
import { currencyToDecimal } from '../util';

@Pipe({
  name: 'currency'
})
export class CurrencyPipe implements PipeTransform {

  transform(value: unknown, currency?: CurrencyModel, ...args: unknown[]): unknown {
    if (!currency) {
      return `${currencyToDecimal(value)}`;
    }
    return `${currency.alphabetic_code} ${currencyToDecimal(value)}`;
  }

}
